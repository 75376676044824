import React from 'react';
import { PostAdd, InsertChart, ImportContacts, Group, Domain, LibraryBooks, LibraryAdd } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

// [選單]系統管理者
export const sidebarNavsAdmin = [
  {
    // 店家管理
    name: <IntlMessages id={'sidebar.manage.store'} />,
    type: 'section',
    children: [
      /*{
        // 首頁
        name: <IntlMessages id={'pages.firstPage'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/first-page',
      },*/
      {
        // 店家資料列表
        name: <IntlMessages id={'sidebar.store.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/list',
      },
      {
        // 帳號資料列表
        name: <IntlMessages id={'sidebar.store.account.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/account/list',
      },
      /*{
        // 新增店家
        name: <IntlMessages id={'sidebar.store.create'} />,
        type: 'item',
        icon: <LibraryAdd />,
        link: '/store/create',
      },*/
      {
        // 車輛管理
        name: <IntlMessages id={'sidebar.vehicle.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/vehicle/list',
      },
      {
        // 設備管理
        name: <IntlMessages id={'sidebar.device.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/device/list',
      },
      /*{
        // 路線管理
        name: <IntlMessages id={'sidebar.vehicle.route.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/route/list',
      },
      {
        // 車輛排班
        name: <IntlMessages id={'sidebar.timetable.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/timetable/list',
      },*/
      {
        // 商品資料列表
        name: <IntlMessages id={'sidebar.product.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/product/list',
      },
      {
        // 分類資料列表
        name: <IntlMessages id={'sidebar.category.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/category/list',
      },
      {
        // 訂單交易記錄
        name: <IntlMessages id={'sidebar.order.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/order/list',
      },
    ],
  },
  /*{
    // 廣告查核
    name: <IntlMessages id={'sidebar.manage.adcheck'} />,
    type: 'section',
    children: [
      {
        // 訂閱送件查核
        name: <IntlMessages id={'sidebar.adcheck.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/adcheck/list',
      },
    ],
  },*/
  {
    // 廣告訂閱
    name: <IntlMessages id={'sidebar.manage.ad'} />,
    type: 'section',
    children: [
      {
        // 會員資料列表
        name: <IntlMessages id={'sidebar.member.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/member/list',
      },
      {
        // 廣告內容上架
        name: <IntlMessages id={'sidebar.playlist.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/playlist/list',
      },
      {
        // 廣告設備訂閱
        name: <IntlMessages id={'sidebar.ad.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/ad/list',
      },
      /*{
        // 廣告執行記錄
        name: <IntlMessages id={'sidebar.adrecord.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/adrecord/list',
      },
      {
        // 廣告成效分析
        name: <IntlMessages id={'sidebar.effect-ad.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/effect-ad/list',
      },
      {
        // 遊戲成效分析
        name: <IntlMessages id={'sidebar.effect-game.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/effect-game/list',
      },*/
    ],
  },
  {
    // 報表管理
    name: <IntlMessages id={'sidebar.chart'} />,
    type: 'section',
    children: [
      /*{
        // 訂單交易記錄
        name: <IntlMessages id={'sidebar.order.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/order/list',
      },*/
      {
        // 直播設定管理
        name: <IntlMessages id={'sidebar.vending.streaming.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/vending/streaming/list',
      },
      {
        // 後台流量紀錄
        name: <IntlMessages id={'sidebar.chart.cms'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/chart/cms',
      },
      {
        // 服務流量紀錄
        name: <IntlMessages id={'sidebar.chart.vending'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/chart/vending',
      },
    ],
  },
];

// [選單]店家管理者
export const sidebarNavsStore = [
  {
    // 店家管理
    name: <IntlMessages id={'sidebar.manage.store'} />,
    type: 'section',
    children: [
      /*{
        // Dashboard
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/dashboard/crm',
      },*/
      {
        // 首頁
        name: <IntlMessages id={'pages.firstPage'} />,
        type: 'item',
        icon: <InsertChart />,
        link: '/first-page',
      },
      {
        // 店家資訊
        name: <IntlMessages id={'sidebar.store.info'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/info',
      },
      {
        // 帳號管理
        name: <IntlMessages id={'sidebar.store.account.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/store/account/list',
      },
    ],
  },
  {
    // 廣告管理
    name: <IntlMessages id={'sidebar.manage.ad'} />,
    type: 'section',
    children: [
      {
        // 廣告內容上架
        name: <IntlMessages id={'sidebar.playlist.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/playlist/list',
      },
      {
        // 廣告設備訂閱
        name: <IntlMessages id={'sidebar.ad.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/ad/list',
      },
      {
        // 廣告執行記錄
        name: <IntlMessages id={'sidebar.adrecord.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/adrecord/list',
      },
      {
        // 廣告成效分析
        name: <IntlMessages id={'sidebar.effect-ad.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/effect-ad/list',
      },
      {
        // 遊戲成效分析
        name: <IntlMessages id={'sidebar.effect-game.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/effect-game/list',
      },
    ],
  },
  {
    // 報表管理
    name: <IntlMessages id={'sidebar.chart'} />,
    type: 'section',
    children: [
      {
        // 訂單交易記錄
        name: <IntlMessages id={'sidebar.order.list'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/order/list',
      },
      {
        // 資料下載
        name: <IntlMessages id={'sidebar.member.download'} />,
        type: 'item',
        icon: <LibraryBooks />,
        link: '/member/download',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
