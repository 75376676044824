import http from '../http-common';

const Product = {
  list: (storeCode, cateCode) => {
    return http.post('/v1/product/list/' + storeCode, {
      cateCode: cateCode,
    });
  },
  add: (storeCode, productData, accessToken) => {
    return http.post('/product/' + storeCode, productData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, productData, accessToken) => {
    return http.put('/product/' + storeCode, productData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, prodCode, accessToken) => {
    return http.post(
      '/product/remove/' + storeCode,
      {
        prodCode: prodCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Category = {
  list: storeCode => {
    return http.post('/category/list/' + storeCode);
  },
  add: (storeCode, cateData, accessToken) => {
    return http.post('/category/' + storeCode, cateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, cateData, accessToken) => {
    return http.put('/category/' + storeCode, cateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, cateCode, accessToken) => {
    return http.post(
      '/category/remove/' + storeCode,
      {
        cateCode: cateCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Activity = {
  list: storeCode => {
    return http.post('/activity/list/' + storeCode);
  },
  add: (storeCode, activityData, accessToken) => {
    return http.post('/activity/' + storeCode, activityData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, activityData, accessToken) => {
    return http.put('/activity/' + storeCode, activityData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, actCode, accessToken) => {
    return http.post(
      '/activity/remove/' + storeCode,
      {
        actCode: actCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};
const Banner = {
  list: storeCode => {
    return http.post('/v1/activity/list/' + storeCode);
  },
  add: (storeCode, bannerData, accessToken) => {
    return http.post('/banner/' + storeCode, bannerData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, bannerData, accessToken) => {
    return http.put('/banner/' + storeCode, bannerData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, actCode, accessToken) => {
    return http.post(
      '/banner/remove/' + storeCode,
      {
        actCode: actCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Pointcard = {
  list: storeCode => {
    return http.post('/pointcard/list/' + storeCode);
  },
  add: (storeCode, pcData, accessToken) => {
    return http.post('/pointcard/add/' + storeCode, pcData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, pcData, accessToken) => {
    return http.put('/pointcard/' + storeCode, pcData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, pcCode, accessToken) => {
    return http.post(
      '/pointcard/remove/' + storeCode,
      {
        pcCode: pcCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Coupon = {
  list: storeCode => {
    return http.post('/coupon/list/' + storeCode);
  },
  usageList: (storeCode, accessToken) => {
    return http.post(
      '/coupon/usage/' + storeCode,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, cpnCode, accessToken) => {
    return http.post('/coupon/add/' + storeCode, cpnCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, cpnCode, accessToken) => {
    return http.put('/coupon/' + storeCode, cpnCode, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  remove: (storeCode, cpnCode, accessToken) => {
    return http.post(
      '/coupon/remove/' + storeCode,
      {
        cpnCode: cpnCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const Image = {
  upload: (storeCode, folder, file) => {
    let formData = new FormData();
    formData.append('file', file);
    return http.post(`/store/${storeCode}/upload/${folder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFiles: () => {
    return http.get('/files');
  },
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/storeImage`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        responseType: 'arraybuffer',
      },
    );
  },
};

const Order = {
  list: storeCode => {
    return http.post('/order/history/list/' + storeCode);
  },
  checkout: async (storeCode, accessToken) => {
    const response = await http.post('/v1/order/checkout/' + storeCode);
    return response;
  },
  ispaid: async (storeCode, accessToken) => {
    const response = await http.post('/v1/order/ispaid/' + storeCode);
    return response;
  },
  history: async (storeCode, accessToken) => {
    const response = await http.post('/v1/order/history/' + storeCode);
    return response;
  },

  checkoutOrder: async (storeCode, accessToken, prodTotalPrice, order) => {
    var checkout = {
      productList: [],
    };
    for (let i = 0; i < order.productList.length; i++) {
      var model = {
        prodCode: order.productList[i].prodCode,
        // prodCode: "",
        prodName: order.productList[i].prodName,
        prodSalePrice: order.productList[i].prodSalePrice,
        prodSaleQty: order.productList[i].prodQty,
      };
      checkout.productList.push(model);
    }

    const valuesArray = JSON.parse(JSON.stringify(checkout.productList));

    const response = await http.post(
      '/v1/order/checkout/' + storeCode,
      {
        prodTotalPrice: prodTotalPrice,
        productList: valuesArray,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
    return response;
  },
  checkOrderPaidStatus: async (storeCode, accessToken, paymentId) => {
    const response = await http.post('/v1/order/ispaid/' + storeCode);
    return response;
  },
  getOrderRecords: async (storeCode, accessToken) => {
    const response = await http.post('/v1/order/history/' + storeCode);
    return response;
  },
};

const log = {
  addEasy: (storeCode, logUrl, accessToken, status) => {
    return http.post(
      `/log/${storeCode}`,
      {
        requestUrl: logUrl,
        requestMethod: 'POST',
        responseStatus: status,
      },
      /*{
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      },*/
    );
  },
  add: (storeCode, logData, accessToken) => {
    return http.post(`/log/${storeCode}`, logData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/log`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listFrontend: (storeCode, accessToken) => {
    return http.post(
      `/log/frontend/parser/${storeCode}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listCms: (storeCode, accessToken) => {
    return http.post(
      `/log/cms/parser/${storeCode}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listFrontendAllStore: accessToken => {
    return http.get(
      `/log/frontend/parser/all`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listCmsAllStore: accessToken => {
    return http.get(
      `/log/cms/parser/all`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  listVending: (accessToken) => {
    return http.post(
      `/log/vending/parser`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const store = {
  download: (storeCode, accessToken) => {
    return http.post(
      `/store/${storeCode}/download/storeData`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        responseType: 'arraybuffer',
      },
    );
  },
};

const Message = {
  list: (storeCode, accessToken) => {
    return http.get(
      '/message/list/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, accessToken, messageData) => {
    return http.post('/message/' + storeCode, messageData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  quota: (storeCode, accessToken) => {
    return http.get(
      '/message/quota/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
};

const ThirdParty = {
  list: (storeCode, accessToken) => {
    return http.get(
      '/thirdparty/list/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
    );
  },
  add: (storeCode, accessToken, thirdpartyData) => {
    return http.post('/thirdparty/' + storeCode, thirdpartyData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
  modify: (storeCode, accessToken, thirdpartyData) => {
    return http.put('/thirdparty/' + storeCode, thirdpartyData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};

const Game = {
  records: async (storeCode, accessToken) => {
    const response = await http.get(
      '/game/records/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    log.add(storeCode, {
      requestUrl: '/game/' + storeCode,
      requestMethod: 'POST',
      responseStatus: response.status,
    });
    return response;
  },
  recordsAll: accessToken => {
    const response = http.get(
      '/game/all/records/',
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    return response;
  },
};

const Tag = {
  list: async (storeCode, accessToken) => {
    const response = await http.get(
      '/store/members/tag/' + storeCode,
      {},
      {
        headers: {
          Authorization: 'Brear ' + accessToken,
        },
      },
    );
    return response;
  },
  add: async (storeCode, accessToken, tagData) => {
    const response = await http.post('/store/members/tag/' + storeCode, tagData, {
      headers: {
        Authorization: 'Brear ' + accessToken,
      },
    });
    return response;
  },
  modify: async (storeCode, accessToken, tagData) => {
    const response = await http.put('/store/members/tag/' + storeCode, tagData, {
      headers: {
        Authorization: 'Brear ' + accessToken,
      },
    });
  },
};

//以下廣告車新增
const Playlist = {
  query: async storeId => {
    const response = await http.post('/playlist/query', {
      storeId: storeId,
    });
    return response;
  },
  add: async (storeId, playlistData, materialData) => {
    const response = await http.post('/playlist/add', {
      storeId: storeId,
      playlist_data: playlistData,
      material_data: materialData,
    });
    return response;
  },
  delete: async (storeId, playlistId) => {
    const response = await http.post('/playlist/delete', {
      storeId: storeId,
      playlistId: playlistId,
    });
    return response;
  },
  update: async (storeId, playlistData) => {
    const response = await http.post('/playlist/update', {
      storeId: storeId,
      playlist_data: playlistData,
    });
    return response;
  },
};

const Material = {
  upload: (storeCode, folder, file) => {
    let formData = new FormData();
    formData.append('file', file);
    return http.post(`/upload/store/${storeCode}/${folder}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  add: async (storeId, playlistId, materialData) => {
    const response = await http.post('/material/add', {
      storeId: storeId,
      playlistId: playlistId,
      material_data: materialData,
    });
    return response;
  },
  delete: async (storeId, playlistId, materialId) => {
    const response = await http.post('/material/delete', {
      storeId: storeId,
      playlistId: playlistId,
      materialId: materialId,
    });
    return response;
  },
  update: async (storeId, playlistId, materialData) => {
    const response = await http.post('/material/update', {
      storeId: storeId,
      playlistId: playlistId,
      material_data: materialData,
    });
    return response;
  },
};

const Ad = {
  query: async () => {
    const response = await http.post('/ad/query', {});
    return response;
  },
  query: async storeId => {
    const response = await http.post('/ad/query', {
      storeId: storeId,
    });
    return response;
  },
  query: async (storeId, adId) => {
    const response = await http.post('/ad/query', {
      storeId: storeId,
      adId: adId,
    });
    return response;
  },
  add: async (storeId, adData) => {
    const response = await http.post('/ad/add', {
      storeId: storeId,
      ad_data: adData,
    });
    return response;
  },
  update: async (storeId, adId, adData) => {
    const response = await http.post('/ad/update', {
      storeId: storeId,
      adId: adId,
      ad_data: adData,
    });
    return response;
  },
  delete: async (storeId, adId) => {
    const response = await http.post('/ad/delete', {
      storeId: storeId,
      adId: adId,
    });
    return response;
  },
  queryCheck: async payStatus => {
    const response = await http.post('/ad/check/query', {
      payStatus: payStatus,
    });
    return response;
  },
  updateCheck: async (storeId, adId, payStatus) => {
    const response = await http.post('/ad/check/update', {
      storeId: storeId,
      adId: adId,
      payStatus: payStatus,
    });
    return response;
  },
};

const AdRecord = {
  query: async storeId => {
    const response = await http.post('/ad/record/query', {
      storeId: storeId,
    });
    return response;
  },
  queryEffect: async (storeId, moveType) => {
    const response = await http.post('/ad/record/query', {
      storeId: storeId,
      moveType: moveType,
    });
    return response;
  },
  query: async (storeId, adId, broadcastDate) => {
    const response = await http.post('/ad/record/query', {
      storeId: storeId,
      adId: adId,
      broadcastDate: broadcastDate,
    });
    return response;
  },
  detail: async (storeId, adId, recordId) => {
    const response = await http.post('/ad/record/detail', {
      storeId: storeId,
      adId: adId,
      recordId: recordId,
    });
    return response;
  },
  detailEffectAd: async (storeId, adId, recordId) => {
    const response = await http.post('/effect/ad/detail', {
      storeId: storeId,
      adId: adId,
      recordId: recordId,
    });
    return response;
  },
  detailEffectGame: async (storeId, adId, recordId) => {
    const response = await http.post('/effect/game/detail', {
      storeId: storeId,
      adId: adId,
      recordId: recordId,
    });
    return response;
  },
};

const Codename = {
  query: async () => {
    const response = await http.post('/codename/query', {});
    return response;
  },
};

const Games = {
  /*與原本的Game查詢不同, 之後可合併*/
  query: async () => {
    const response = await http.post('/game/query', {});
    return response;
  },
};

const Device = {
  query: async macAddr => {
    const response = await http.post('/device/query', {
      macAddr: macAddr,
    });
    return response;
  },
  add: async deviceData => {
    const response = await http.post('/device/add', {
      device_data: deviceData,
    });
    return response;
  },
  update: async deviceData => {
    const response = await http.post('/device/update', {
      device_data: deviceData,
    });
    return response;
  },
  delete: async macAddr => {
    const response = await http.post('/device/delete', {
      macAddr: macAddr,
    });
    return response;
  },
};

const Vehicle = {
  query: async () => {
    const response = await http.post('/vehicle/query', {});
    return response;
  },
  query: async vehicleId => {
    const response = await http.post('/vehicle/query', {
      vehicleId: vehicleId,
    });
    return response;
  },
  add: async vehicleData => {
    const response = await http.post('/vehicle/add', {
      vehicle_data: vehicleData,
    });
    return response;
  },
  update: async vehicleData => {
    const response = await http.post('/vehicle/update', {
      vehicle_data: vehicleData,
    });
    return response;
  },
  delete: async vehicleId => {
    const response = await http.post('/vehicle/delete', {
      vehicleId: vehicleId,
    });
    return response;
  },
};

const VehicleRoute = {
  query: async () => {
    const response = await http.post('/vehicle/route/query', {});
    return response;
  },
  query: async id => {
    const response = await http.post('/vehicle/route/query', {
      id: id,
    });
    return response;
  },
  add: async routeData => {
    const response = await http.post('/vehicle/route/add', {
      route_data: routeData,
    });
    return response;
  },
  update: async routeData => {
    const response = await http.post('/vehicle/route/update', {
      route_data: routeData,
    });
    return response;
  },
  delete: async id => {
    const response = await http.post('/vehicle/route/delete', {
      id: id,
    });
    return response;
  },
};

const Timetable = {
  query: async () => {
    const response = await http.post('/timetable/query', {});
    return response;
  },
  query: async timetableId => {
    const response = await http.post('/timetable/query', {
      timetableId: timetableId,
    });
    return response;
  },
  queryVehicle: async vehicleId => {
    const response = await http.post('/timetable/query', {
      vehicleId: vehicleId,
    });
    return response;
  },
  add: async (vehicleId, timetableData) => {
    const response = await http.post('/timetable/add', {
      vehicleId: vehicleId,
      timetable_data: timetableData,
    });
    return response;
  },
  update: async (timetableId, vehicleId, timetableData) => {
    const response = await http.post('/timetable/update', {
      timetableId: timetableId,
      vehicleId: vehicleId,
      timetable_data: timetableData,
    });
    return response;
  },
  delete: async timetableId => {
    const response = await http.post('/timetable/delete', {
      timetableId: timetableId,
    });
    return response;
  },
};

const VendingStreaming = {
  query: async () => {
    const response = await http.post('/vending/streaming/query', {});
    return response;
  },
  query: async id => {
    const response = await http.post('/vending/streaming/query', {
      id: id,
    });
    return response;
  },
  add: async streamingData => {
    const response = await http.post('/vending/streaming/add', {
      streaming_data: streamingData,
    });
    return response;
  },
  update: async streamingData => {
    const response = await http.post('/vending/streaming/update', {
      streaming_data: streamingData,
    });
    return response;
  },
  delete: async id => {
    const response = await http.post('/vending/streaming/delete', {
      id: id,
    });
    return response;
  },
};

export default {
  Product,
  Image,
  Category,
  Activity,
  Banner,
  Pointcard,
  Coupon,
  log,
  Order,
  store,
  Message,
  ThirdParty,
  Game,
  Tag,

  Playlist,
  Material,
  Ad,
  AdRecord,
  Codename,
  Games,
  Device,
  Vehicle,
  VehicleRoute,
  Timetable,
  VendingStreaming,
};
