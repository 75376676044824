import styled from 'styled-components';

export const CrossButton = styled.button`
  color: white;
  position: absolute;
  top: 10px;
  right: -10px;
  z-index: 100;
  width: 23px;
  height: 23px;
  border: 2px solid white;
  background-color: #f50057;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;
