import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import IntlMessages from '@jumbo/utils/IntlMessages';
import FormikContainer from './FormikContainer';
import qs from 'qs';
import 'react-day-picker/dist/style.css';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.device.list'} />, link: '/device/list' },
  { label: <IntlMessages id={'pages.device.edit'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
}));

export default function Table() {
  const classes = useStyles();
  const [playlist, setPlaylist] = React.useState();
  const [device, setDevice] = React.useState();
  const qstrobj = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const macAddr = qstrobj.macAddr;
  const insStatus = macAddr && macAddr != 0 ? false : true;
  //const readonly = qstrobj.readonly;
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  React.useEffect(() => {
    getPlaylist();
    getDevice();
  }, []);

  const getPlaylist = async () => {
    //待修改要 不指定storeId
    await ApiService.Playlist.query(storeId)
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/playlist/query`, token, response.status);
        setPlaylist(response.data.playlist);
      })
      .catch(err => console.log('err', err));
  };

  const getDevice = async () => {
    if (macAddr) {
      await ApiService.Device.query(macAddr)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/device/query`, token, response.status);
          if (response.data.deviceList.length > 0) {
            setDevice(response.data.deviceList[0]);
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  return (
    <PageContainer
      heading={macAddr ? <IntlMessages id="pages.device.edit" /> : <IntlMessages id="pages.device.new" />}
      breadcrumbs={breadcrumbs}>
      <Box className={classes.sectionBoard}>
        <FormikContainer rowData={device} playlist={playlist} insStatus={insStatus} />
      </Box>
    </PageContainer>
  );
}
