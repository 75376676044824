import React, { Component } from 'react';

class Field extends Component {
  render() {
    return (
      <div className="row align-items-stretch mb-2">
        <div className="col-md-12">
          <input
            className="form-control"
            id={this.props.name}
            type={this.props.type}
            placeholder={this.props.placeholder}
            data-sb-validations="required"
            name={this.props.name}
            // onChange={this.props.onChange}
            // onBlur={this.props.onBlur}
            // value={this.props.value}
            // onChange={e => this.props.onChange(e)}
          />
        </div>
      </div>
    );
  }
}

export default Field;
