import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import moment from 'moment';
import { zhTW } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MapDialog from '../../RouteMap/MapDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
}));

function FormikContainer(props) {
  let { rowData, codename, insStatus, readonly } = props;
  readonly = parseInt(readonly); //0:全編輯, 1:全唯讀, 2:部分唯讀
  const history = useHistory();
  const classes = useStyles();
  //選擇日期
  const [days, setDays] = React.useState();
  //預設月份
  let mMonth = moment().toDate();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
    history.push(`/adcheck/list`);
  };
  //選單項目
  const selectMoveType = [{ key: '請選擇...', value: 0 }];
  const selectVehicleType = [{ key: '請選擇...', value: 0 }];
  const selectVehicleRoute = [{ key: '請選擇...', value: 0 }];
  const selectPlayMode = [{ key: '請選擇...', value: 0 }];
  const selectPlayId1 = [{ key: '請選擇...', value: 0 }];
  const selectPlayId2 = [{ key: '請選擇...', value: 0 }];
  const selectGameId = [{ key: '請選擇...', value: 0 }];
  //初始值
  const initialValues = {
    name: '',
    moveType: 'move_play',
    vehicleType: 'car',
    vehicleRoute: 0,
    broadcastDate1: '',
    broadcastDate2: '',
    broadcastDate3: '',
    broadcastDate4: '',
    broadcastDate5: '',
    broadcastDate6: '',
    mode: 'basic',
    playId1: 0,
    playId2: 0,
    fixLocation: '',
    gameId: 0,
    gameEndUri: '',
    gameWinUri: '',
  };
  console.log('rowData:', rowData);
  console.log('codename:', codename);
  if (codename) {
    //設定選單項目
    codename.codeMoveType.forEach(item => {
      selectMoveType.push({ key: item.name, value: item.code });
    });
    codename.codeVehicleType.forEach(item => {
      selectVehicleType.push({ key: item.name, value: item.code });
    });
    codename.routeList.forEach(item => {
      selectVehicleRoute.push({ key: item.name, value: item.id });
    });
    codename.codePlayMode.forEach(item => {
      selectPlayMode.push({ key: item.name, value: item.code });
    });
    codename.playlist.forEach(item => {
      selectPlayId1.push({ key: item.name, value: item.id });
    });
    codename.playlist.forEach(item => {
      selectPlayId2.push({ key: item.name, value: item.id });
    });
    codename.game.forEach(item => {
      selectGameId.push({ key: item.name, value: item.id });
    });
    //設定初始資料
    if (rowData) {
      //第2次render時才有資料
      initialValues.name = rowData.name;
      initialValues.moveType = rowData.moveType.code;
      initialValues.vehicleType = rowData.vehicle.type.code;
      initialValues.vehicleRoute = rowData.vehicle.route.id;
      initialValues.mode = rowData.mode.code;
      initialValues.playId1 = rowData.playlistOne.id;
      initialValues.playId2 = rowData.playlistTwo.id;
      initialValues.fixLocation = rowData.fixLocation;
      initialValues.gameId = rowData.game.id;
      initialValues.gameEndUri = rowData.gameEndUri;
      initialValues.gameWinUri = rowData.gameWinUri;
      //initialValues.broadcastDate = days && days.length > 0 ? days.map(d => format(d, 'yyyy/MM/dd')).join(',') : rowData.broadcastDate;
    }
    const dayFormat = 'YYYY-MM-DD';
    let mdays = [];
    if (days && days.length > 0) {
      //當日曆選擇日期時
      mdays = days.sort((a, b) => a.valueOf() - b.valueOf());
    } else {
      //當讀取資料初始日期時
      if (rowData) {
        let arrayDays = [];
        rowData.broadcastDate.split(',').forEach(item => {
          arrayDays.push(moment(item, dayFormat));
        });
        mdays = arrayDays.sort((a, b) => a.valueOf() - b.valueOf());
      }
    }
    initialValues.broadcastDate1 = mdays && mdays.length > 0 ? moment(mdays[0]).format(dayFormat) : '';
    initialValues.broadcastDate2 = mdays && mdays.length > 1 ? moment(mdays[1]).format(dayFormat) : '';
    initialValues.broadcastDate3 = mdays && mdays.length > 2 ? moment(mdays[2]).format(dayFormat) : '';
    initialValues.broadcastDate4 = mdays && mdays.length > 3 ? moment(mdays[3]).format(dayFormat) : '';
    initialValues.broadcastDate5 = mdays && mdays.length > 4 ? moment(mdays[4]).format(dayFormat) : '';
    initialValues.broadcastDate6 = mdays && mdays.length > 5 ? moment(mdays[5]).format(dayFormat) : '';
  }
  // 送出前檢查欄位
  const validationSchema = Yup.object({
    name: Yup.string().required('**必填'),
    moveType: Yup.string().min(2, '**必填'),
    vehicleType: Yup.string().min(2, '**必填'),
    mode: Yup.string().min(2, '**必填'),
    playId1: Yup.number().min(1, '**必填'),
    broadcastDate1: Yup.string().required('**必填'),
  });

  // 新增 or 更新
  const onSubmit = async formData => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    // 過程中判斷insStatus 新增或更新
    /*
    let broadcastDate = [];
    if (formData.broadcastDate1) {
      broadcastDate.push(formData.broadcastDate1);
    }
    if (formData.broadcastDate2) {
      broadcastDate.push(formData.broadcastDate2);
    }
    if (formData.broadcastDate3) {
      broadcastDate.push(formData.broadcastDate3);
    }
    if (formData.broadcastDate4) {
      broadcastDate.push(formData.broadcastDate4);
    }
    if (formData.broadcastDate5) {
      broadcastDate.push(formData.broadcastDate5);
    }
    if (formData.broadcastDate6) {
      broadcastDate.push(formData.broadcastDate6);
    }
    let adData = {
      name: formData.name,
      moveTypeCode: formData.moveType,
      vehicleTypeCode: formData.vehicleType,
      vehicleRouteId: formData.vehicleRoute,
      playModeCode: formData.mode,
      playlistOneId: formData.playId1,
      playlistTwoId: formData.playId2,
      fixLocation: formData.fixLocation,
      gameId: formData.gameId,
      gameWinUri: formData.gameWinUri,
      gameEndUri: formData.gameEndUri,
      broadcastDate: broadcastDate.join(','),
      marquee: '',
      memo: '',
    };
    if (insStatus) {
      //insStatus=true 新增
      await ApiService.Ad.add(storeId, adData).then(async res => {
        await ApiService.log.addEasy(storeCode, `/ad/add/${storeCode}`, token, res.status);
        setMessage(res.data.message);
      });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      await ApiService.Ad.update(storeId, rowData.id, adData).then(async res => {
        await ApiService.log.addEasy(storeCode, `/ad/update/${storeCode}`, token, res.status);
        setMessage(res.data.message);
      });
      handleClickOpen();
    }*/
    await ApiService.Ad.updateCheck(storeId, rowData.id, 'accept').then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/check/update`, token, res.status);
      setMessage(res.data.message);
    });
    handleClickOpen();
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          //console.log('formik', formik);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box className={classes.section}>
                    <InputLabel id="lbName">廣告名稱*</InputLabel>
                    <ErrorMessage name="name" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="input"
                      name="name"
                      fullWidth
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                </Grid>
                {/*左邊*/}
                <Grid item xs={6}>
                  <Box className={classes.section}>
                    <InputLabel id="lbMoveType">廣告類型*</InputLabel>
                    <ErrorMessage name="moveType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="moveType"
                      options={selectMoveType}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1, 2].includes(readonly)}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbVehicleType">車種*</InputLabel>
                    <ErrorMessage name="vehicleType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicleType"
                      options={selectVehicleType}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1, 2].includes(readonly)}
                    />
                  </Box>
                  {formik.values.moveType == 'move_play' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbVehicleRoute">路線</InputLabel>
                      <FormikControl
                        control="select"
                        name="vehicleRoute"
                        options={selectVehicleRoute}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                      <MapDialog routeId={formik.values.vehicleRoute} disabled={formik.values.vehicleRoute == 0} />
                    </Box>
                  ) : null}
                  {formik.values.moveType == 'fix_play' || formik.values.moveType == 'fix_game' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbFixLocation">定點地址</InputLabel>
                      <FormikControl
                        control="input"
                        name="fixLocation"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  <Box className={classes.section}>
                    <InputLabel id="lbMode">播放模式*</InputLabel>
                    <ErrorMessage name="mode" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="mode"
                      options={selectPlayMode}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbPlayId1">播放清單(主)*</InputLabel>
                    <ErrorMessage name="playId1" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="playId1"
                      options={selectPlayId1}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                  {formik.values.mode != 'basic' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbPlayId2">播放清單(副)</InputLabel>
                      <FormikControl
                        control="select"
                        name="playId2"
                        options={selectPlayId2}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {formik.values.moveType == 'fix_game' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameId">遊戲種類</InputLabel>
                      <FormikControl
                        control="select"
                        name="gameId"
                        options={selectGameId}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {formik.values.moveType == 'fix_game' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameEndUri">請輸入遊戲結束連結網址(URI)</InputLabel>
                      <FormikControl
                        control="input"
                        name="gameEndUri"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {formik.values.moveType == 'fix_game' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameWinUri">請輸入遊戲優勝連結網址(URI)</InputLabel>
                      <FormikControl
                        control="input"
                        name="gameWinUri"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                </Grid>
                {/*右邊*/}
                <Grid item xs={6}>
                  <Box className={classes.section}>
                    <InputLabel id="lbBroadcastDate">請選擇日期(max:6天)*</InputLabel>
                    <ErrorMessage name="broadcastDate1" component="div" className={classes.sectionValidErrMsg} />
                    <DayPicker
                      name="broadcastDatePicker"
                      locale={zhTW}
                      numberOfMonths={[1, 2].includes(readonly) ? 0 : 1}
                      mode="multiple"
                      max={6}
                      defaultMonth={mMonth}
                      selected={days}
                      onSelect={setDays}
                    />
                    {formik.values.broadcastDate1 != '' ? (
                      <FormikControl
                        label="Day1."
                        control="input"
                        name="broadcastDate1"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate2 != '' ? (
                      <FormikControl
                        label="Day2."
                        control="input"
                        name="broadcastDate2"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate3 != '' ? (
                      <FormikControl
                        label="Day3."
                        control="input"
                        name="broadcastDate3"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate4 != '' ? (
                      <FormikControl
                        label="Day4."
                        control="input"
                        name="broadcastDate4"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate5 != '' ? (
                      <FormikControl
                        label="Day5."
                        control="input"
                        name="broadcastDate5"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate6 != '' ? (
                      <FormikControl
                        label="Day6."
                        control="input"
                        name="broadcastDate6"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit">
                      查核通過
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[廣告更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
