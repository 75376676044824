import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.playlist.list'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [playlist, setPlaylist] = React.useState();
  React.useEffect(() => {
    getPlaylist();
  }, []);
  const getPlaylist = async () => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Playlist.query(storeId)
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/playlist/query/${storeCode}`, token, response.status);
        setPlaylist(response.data.playlist);
      })
      .catch(async err => {
        console.log('err', err);
        /*if (err.response.status === 401) {
          const res = await refreshToken(); //更新token
          console.log('refreshToken:', res);
        }*/
      });
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.playlist.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable playlist={playlist} getPlaylist={getPlaylist} />
      </Box>
      {/*<Box className={classes.section}>
        <FormDialog playlist={playlist} getPlaylist={getPlaylist} insStatus={true} />
      </Box>*/}
    </PageContainer>
  );
}
