import React, { useState, useRef } from 'react';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '../../../@jumbo/constants/TableIcons';
import { CardMedia, Button } from '@material-ui/core';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import IntlMessages from '@jumbo/utils/IntlMessages';
// table 欄位標題設定
const columns = {
  childColumns: [
    {
      title: 'No.',
      editable: 'never',
      render: rowData => rowData.tableData.id + 1,
    },
    { title: '名稱', field: 'name' },
    { title: '類別', field: 'type', editable: 'never' },
    { title: '格式', field: 'format', editable: 'never' },
    { title: '解析度', field: 'resolution', editable: 'never' },
    {
      title: '時間(秒)',
      field: 'duration',
      render: rowData => `${rowData.duration}`,
    },
    { title: '大小(MB)', field: 'fileSize', editable: 'never' },
  ],
};

export default function MaterialDataTable(props) {
  const { adFormat, setFieldValue, material, insStatus, playlistId } = props;
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  for (let item of material) {
    //新增或更新 都有fileURL 欄位值
    if (!item.fileURL) {
      item.fileURL = item.fileName;
    }
  }
  const [data, setData] = useState(material);
  const inputFile = useRef(null);

  const inputFileButton = () => {
    inputFile.current.click();
  };
  const inputFileOnChange = async event => {
    if (event.target.files[0] !== undefined) {
      const mediaFile = event.target.files[0];
      const fileURL = URL.createObjectURL(mediaFile);

      let resolution = new Promise(res => {
        //取得圖片 長x寬
        var img = new Image();
        img.onload = function() {
          console.log('resolution', this.width + ' ' + this.height);
          res(this.width + '*' + this.height);
        };
        img.src = fileURL;
      });
      let videoResolution = new Promise(res => {
        //取得影片 長x寬
        const video = document.createElement('video');
        video.onloadedmetadata = function() {
          //console.log('videoResolution', video.videoWidth+' '+video.videoHeight);
          res(video.videoWidth + '*' + video.videoHeight);
        };
        video.src = fileURL;
        video.load();
      });
      let videoDuration = new Promise(res => {
        //取得影片 時間
        const video = document.createElement('video');
        video.onloadedmetadata = function() {
          //console.log('videoDuration', video.duration);
          res(video.duration.toFixed());
        };
        video.src = fileURL;
        video.load();
      });

      const newData = [];
      newData.push({
        name: mediaFile.name,
        type: mediaFile.type,
        format: adFormat, // === 'video'? '影片' : '圖片',
        resolution: adFormat === '影片' ? await videoResolution : await resolution,
        duration: adFormat === '影片' ? await videoDuration : 20,
        fileSize: (mediaFile.size / (1024 * 1024)).toFixed(2),
        file: mediaFile,
        fileURL: fileURL,
      });
      console.log('newData', newData);

      setData([...data, ...newData]);
      setFieldValue('material', [...data, ...newData]);
    }
  };
  const removeMaterial = rowMaterial => {
    return new Promise((resolve, reject) => {
      if (!insStatus && rowMaterial.id) {
        //更新狀態&&有素材ID -> 刪除material
        //console.log("delete", `${storeId}-${playlistId}-${rowMaterial.id}`)
        ApiService.Material.delete(storeId, playlistId, rowMaterial.id)
          .then(async res => {
            await ApiService.log.addEasy(storeCode, `/material/detete/${storeCode}`, token, res.status);
          })
          .catch(err => {
            console.log('error', err);
          });
      }
      const oldData = JSON.parse(JSON.stringify(data));
      const filteredData = oldData.filter(data => data.fileURL !== rowMaterial.fileURL);
      setFieldValue('material', filteredData);
      resolve(filteredData);
    });
  };
  const updateMaterial = rowMaterial => {
    return new Promise((resolve, reject) => {
      if (!insStatus && rowMaterial.id) {
        //更新狀態&&有素材ID -> 更新material
        let materialData = {
          id: rowMaterial.id,
          name: rowMaterial.name,
          type: rowMaterial.type,
          resolution: rowMaterial.resolution,
          format: rowMaterial.format,
          duration: rowMaterial.duration,
          fileSize: rowMaterial.fileSize,
        };
        ApiService.Material.update(storeId, playlistId, materialData)
          .then(async res => {
            await ApiService.log.addEasy(storeCode, `/material/update/${storeCode}`, token, res.status);
          })
          .catch(err => {
            console.log('error', err);
          });
      }
      const oldData = [...data]; //複製data
      const index = data.findIndex(e => e.fileURL === rowMaterial.fileURL);
      oldData[index].name = rowMaterial.name; //只修改可以改的,整筆複蓋file欄會有問題
      oldData[index].duration = rowMaterial.duration;
      resolve(oldData);
    });
  };
  const actions = [
    {
      icon: () => (
        <div>
          <input
            accept={adFormat === '影片' ? 'video/*' : 'image/*'}
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={event => inputFileOnChange(event)}
          />
          <Button variant="contained" color="primary">
            新增素材
          </Button>
        </div>
      ),
      tooltip: '新增素材',
      isFreeAction: true,
      onClick: event => inputFileButton(),
    },
  ];
  return (
    <MaterialTable
      title="素材列表"
      icons={TABLE_ICONS}
      columns={columns.childColumns}
      data={data}
      actions={actions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        headerStyle: {
          textAlign: 'center',
          backgroundColor: 'orange', //'royalblue',
          color: 'white',
        },
        cellStyle: {
          textAlign: 'center',
        },
        pageSizeOptions: [3, 5, 10],
        pageSize: 3,
      }}
      style={{
        backgroundColor: 'aliceblue',
      }}
      localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="material.table.delete.text" />,
          },
          emptyDataSourceMessage: <h3 style={{ textAlign: 'center' }}>請上傳檔案</h3>,
        },
      }}
      editable={{
        onRowUpdate: rowData => updateMaterial(rowData).then(data => setData(data)),
        onRowDelete: rowData => removeMaterial(rowData).then(data => setData(data)),
      }}
      detailPanel={rowData => {
        return <CardMedia component={rowData.format === '影片' ? 'video' : 'img'} src={rowData.fileURL} autoPlay />;
      }}
    />
  );
}
