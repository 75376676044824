// 解析後端API回應HTTP Status Code為2XX之成功訊息
const popupHttpSuccMessage = res => {
  let msg = '操作成功';
  if (res && res.data && res.data.message) {
    msg = res.data.message;
  }
  popupMsg(msg);
};

// 解析後端API回應HTTP Status Code為4XX、5XX之錯誤訊息
const parseHttpErrorMessage = error => {
  let msg = '系統發生未知錯誤';
  if (error) {
    if (error.response && error.response.data && error.response.data.message) {
      msg = error.response.data.message;
    } else if (error.message) {
      msg = error.message;
    }
  }
  return msg;
};

// 彈出Dialog視窗，用以顯示提示訊息 ( TODO: 先用alert撐著，找時間改用其它dialog取代 )
const popupMsg = msgText => {
  if (msgText) {
    alert(msgText);
  }
};

// 彈出Dialog視窗，用以顯示除錯訊息，可用開關切換 (修改檔案.env.development內參數REACT_APP_DEBUG_MODE值為true/false)
const debugMsg = debugMsgText => {
  const debugMode = process.env.REACT_APP_DEBUG_MODE;
  // popupMsg(`debugMode=${debugMode}`);
  if (debugMode && debugMode === 'true' && debugMsgText) {
    alert('<<< DEBUG_MODE >>> \r\n\r\n' + debugMsgText);
  }
};

const CommonService = {
  popupHttpSuccMessage,
  parseHttpErrorMessage,
  popupMsg,
  debugMsg,
};

export default CommonService;
