import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
//import { device } from '@fake-db/ad-car/device';
// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1 },
    { title: '設備MAC', field: 'macAddr' },
    { title: '設備名稱', field: 'name' },
    { title: '預設播放清單1', field: 'defaultPlay1.name' },
    { title: '預設播放清單2', field: 'defaultPlay2.name' },
    //{ title: '更新時間', field: 'updatedAt', type: 'date', editable: 'never' },
  ],
};

export default function DataTable({ deviceList, getDeviceList }) {
  //const [data, setData] = useState(playlist.playlistBasic.concat(playlist.playlistOccasion).concat(playlist.playlistGame));
  //const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  //const [rowData, setRowData] = React.useState({});
  const history = useHistory();

  // 刪除Device API
  const removeDevice = async rowData => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Device.delete(rowData.macAddr).then(async res => {
      await ApiService.log.addEasy(storeCode, `/device/delete`, token, res.status);
    });
    getDeviceList();
  };

  // 編輯按鈕
  const actions = [
    {
      icon: () => (
        <Button variant="outlined" color="primary">
          新增設備
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增設備',
      onClick: (event, rowData) => {
        history.push(`/device/edit?macAddr=`);
      },
    },
    rowData => ({
      icon: () => <TABLE_ICONS.Edit />,
      tooltip: '編輯設備',
      onClick: () => {
        history.push(`/device/edit?macAddr=${rowData.macAddr}`);
      },
    }),
  ];

  return (
    <MaterialTable
      title=""
      icons={TABLE_ICONS}
      columns={columns.parentsColoumns}
      data={deviceList}
      options={{
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="material.table.delete.text" />,
          },
        },
      }}
      actions={actions}
      editable={{
        onRowDelete: rowData => removeDevice(rowData),
      }}
    />
  );
}
