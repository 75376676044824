import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.vending.streaming.list'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [vendingStreamingList, setVendingStreamingList] = React.useState();
  React.useEffect(() => {
    getVendingStreamingList();
  }, []);
  const getVendingStreamingList = async () => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.VendingStreaming.query()
      .then(async response => {
        //console.log('vendingStreaming', response.data.vendingStreamingList);
        await ApiService.log.addEasy(storeCode, `/vending/streaming/query`, token, response.status);
        setVendingStreamingList(response.data.vendingStreamingList);
      })
      .catch(err => console.log('err', err));
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.vending.streaming.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable vendingStreamingList={vendingStreamingList} getVendingStreamingList={getVendingStreamingList} />
      </Box>
    </PageContainer>
  );
}
