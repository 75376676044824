import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../Components/Formik/FormikControl';
import Button from '@material-ui/core/Button';
import MaterialDataTable from './MaterialDataTable';
import { Box, Grid } from '@material-ui/core';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';

function FormikContainer(props) {
  const { handleClose, rowData, insStatus } = props;
  //console.log('rowdata', rowData);
  const initialValues = {
    playlistId: 0,
    playlistName: '',
    playlistFormat: 0,
    material: [],
  };
  if (!insStatus) {
    initialValues.playlistId = rowData.id;
    initialValues.playlistName = rowData.name;
    initialValues.playlistFormat = rowData.format;
    initialValues.material = rowData.materials;
  }
  // 新增 or 更新
  const onSubmit = async formData => {
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let materials = formData.material;
    let playlistData = {
      name: '',
      format: '',
      resolution: '',
      duration: 0,
      totalFileCount: 0,
      totalFileSize: 0,
    };
    if (insStatus) {
      //insStatus=true 新增
      //if (materials.length > 0) {
      let materialData = [];
      for (let item of materials) {
        //1.上傳素材圖檔
        await ApiService.Material.upload(storeCode, 'material', item.file).then(res => {
          item.fileName = res.data.uuidName;
          ApiService.log.addEasy(storeCode, `/upload/store/${storeCode}/material`, token, res.status);
        });
        //console.log('Upload Success:', JSON.stringify(item));
        //2.記錄playlist資料
        materialData.push({
          name: item.name,
          type: item.type,
          resolution: item.resolution,
          format: item.format,
          duration: item.duration,
          fileSize: item.fileSize,
          fileName: item.fileName,
        });
        playlistData.format = item.format;
        playlistData.resolution = playlistData.resolution.includes(item.resolution)
          ? playlistData.resolution
          : playlistData.resolution.concat(' ', item.resolution);
        playlistData.duration += parseInt(item.duration);
        playlistData.totalFileCount += 1;
        playlistData.totalFileSize += parseFloat(item.fileSize);
      }
      playlistData.name = formData.playlistName;
      playlistData.totalFileSize = playlistData.totalFileSize.toFixed(2); //只取2位小數
      //3.新增playlist資料
      await ApiService.Playlist.add(storeId, playlistData, materialData).then(res => {
        ApiService.log.addEasy(storeCode, `/playlist/add/${storeCode}`, token, res.status);
      });
      //}
    } else {
      //insStatus=false 更新
      //新的素材檔案上傳 (舊的素材更新,刪除 <- 已於MaterialDataTable完成)
      //if (materials.length > 0) {
      for (let item of materials) {
        //1.上傳素材圖檔(有新的上傳)
        if (item.file) {
          await ApiService.Material.upload(storeCode, 'material', item.file).then(async res => {
            item.fileName = res.data.uuidName;
            await ApiService.log.addEasy(storeCode, `/upload/store/${storeCode}/material`, token, res.status);
          });
          //2.新增material資料
          let materialData = {
            name: item.name,
            type: item.type,
            resolution: item.resolution,
            format: item.format,
            duration: item.duration,
            fileSize: item.fileSize,
            fileName: item.fileName,
          };
          await ApiService.Material.add(storeId, initialValues.playlistId, materialData).then(async res => {
            await ApiService.log.addEasy(storeCode, `/material/update/${storeCode}`, token, res.status);
          });
        }
        //3.記錄playlist資料(重算)
        playlistData.format = item.format;
        playlistData.resolution = playlistData.resolution.includes(item.resolution)
          ? playlistData.resolution
          : playlistData.resolution.concat(' ', item.resolution);
        playlistData.duration += parseInt(item.duration);
        playlistData.totalFileCount += 1;
        playlistData.totalFileSize += parseFloat(item.fileSize);
      }
      //}
      //4.更新playlist資料
      playlistData.id = initialValues.playlistId;
      playlistData.name = formData.playlistName;
      playlistData.totalFileSize = playlistData.totalFileSize.toFixed(2); //只取2位小數
      await ApiService.Playlist.update(storeId, playlistData).then(async res => {
        await ApiService.log.addEasy(storeCode, `/playlist/update/${storeCode}`, token, res.status);
      });
    }
    handleClose();
  };
  const onCancel = async () => {
    //取消按鈕
    handleClose();
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {formik => {
        // console.log('formik', formik)
        return (
          <Form>
            <FormikControl
              control="select"
              label="選擇播放清單格式"
              name="playlistFormat"
              disabledControl={formik.values.material.length > 0 ? true : false}
              options={dropdownOptions}
              errors={formik.errors}
              touched={formik.touched}
            />
            <FormikControl
              control="textarea"
              label="播放清單名稱"
              name="playlistName"
              errors={formik.errors}
              touched={formik.touched}
            />
            {formik.values.playlistFormat === 0 ? (
              <h1 style={{ textAlign: 'center' }}>請選擇播放清單格式</h1>
            ) : (
              <MaterialDataTable
                adFormat={formik.values.playlistFormat}
                setFieldValue={formik.setFieldValue}
                material={formik.values.material}
                insStatus={insStatus}
                playlistId={formik.values.playlistId}
              />
            )}
            <Box m={2}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting || formik.values.material.length === 0}>
                {insStatus ? '確定新增' : '確定更新'}
              </Button>
              <Button variant="outlined" color="primary" onClick={onCancel}>
                取消
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormikContainer;

const dropdownOptions = [
  { key: '選擇播放清單格式', value: 0 },
  { key: '影片', value: '影片' },
  { key: '圖片', value: '圖片' },
  /*{ key: '遊戲側欄圖片', value: '遊戲側欄圖片' },*/
];
const validationSchema = Yup.object({
  playlistName: Yup.string().required('Required'),
  playlistFormat: Yup.string().required('Required'),
});
