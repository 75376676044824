import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import MapDialog from '../../RouteMap/MapDialog';
// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1, width: '3%' },
    { title: '直播代碼', field: 'code', width: '20%' },
    { title: '名稱', field: 'name', width: '20%' },
    { title: '直播連結', field: 'description', width: '40%' },
    //{ title: '更新時間', field: 'updatedAt', type: 'date', editable: 'never' },
  ],
};

export default function DataTable({ vehicleRouteList, getVehicleRouteList }) {
  const history = useHistory();

  // 刪除VehicleRoute API
  const removeVehicleRoute = async rowData => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.VehicleRoute.delete(rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/vehicle/route/delete`, token, res.status);
    });
    getVehicleRouteList();
  };

  // 編輯按鈕
  const actions = [
    {
      icon: () => (
        <Button variant="outlined" color="primary">
          新增設定
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增路線',
      onClick: (event, rowData) => {
        history.push(`/route/edit?id=`);
      },
    },
    /*rowData => ({
      icon: () => <MapDialog routeId={rowData.id} />,
    }),*/
    rowData => ({
      icon: () => <TABLE_ICONS.Edit />,
      tooltip: '編輯路線',
      onClick: () => {
        history.push(`/route/edit?id=${rowData.id}`);
      },
    }),
  ];

  return (
    <MaterialTable
      title=""
      icons={TABLE_ICONS}
      columns={columns.parentsColoumns}
      data={vehicleRouteList}
      options={{
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="material.table.delete.text" />,
          },
        },
      }}
      actions={actions}
      editable={{
        onRowDelete: rowData => removeVehicleRoute(rowData),
      }}
    />
  );
}
