/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import { updateStoreAdmin } from '../../../../redux/actions/StoreAdminAction';
import localStorageService from 'services/LocalStorageService';
// import storeAdminDataService from '../../../../services/StoreAdminService';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, ...others } = props;
  const dispatch = useDispatch();
  const [adminAccountVO, setAdminAccountVO] = React.useState(initialValues);

  useEffect(() => {
    if (rowData !== null) {
      setAdminAccountVO({
        ...rowData,
      });
    }
  }, [rowData]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const initialValues = {
    adminAccount: '',
    adminEmail: '',
    adminName: '',
    adminEnabled: false,
  };
  const handleOnSubmit = values => {
    const storeCode = localStorageService.getStoreCode();
    setIsDialogOpen(false);
    // storeAdminDataService.update(values.id, {
    //   adminAccount: values.adminAccount,
    //   adminEmail: values.adminEmail,
    //   adminName: values.adminName,
    //   adminEnabled: values.adminEnabled,
    // })
    dispatch(
      updateStoreAdmin(
        values.id,
        {
          adminAccount: values.adminAccount,
          adminEmail: values.adminEmail,
          adminName: values.adminName,
          adminEnabled: values.adminEnabled,
        },
        storeCode,
      ),
    );
    setAdminAccountVO({
      adminAccount: values.adminAccount,
      adminPassword: values.adminPassword,
      adminName: values.adminName,
      adminEmail: values.adminEmail,
      adminEnabled: values.adminEnabled,
    });
  };
  const validationSchema = yup.object().shape({
    adminAccount: yup.string('Admin account').required('必填欄位'),
    adminName: yup.string('Admin Name').required('必填欄位'),
    adminEmail: yup
      .string('Email Address')
      .email('電子郵件格式有誤')
      .required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改管理者</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={adminAccountVO} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    required
                    as={TextField}
                    autoFocus
                    margin="dense"
                    id="adminAccount"
                    name="adminAccount"
                    label="店管帳號"
                    type="text"
                    fullWidth
                    value={values.adminAccount}
                    onChange={handleChange}
                    error={touched.adminAccount && Boolean(errors.adminAccount)}
                    helperText={touched.adminAccount && errors.adminAccount}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="adminName"
                    name="adminName"
                    label="店管名稱"
                    type="text"
                    fullWidth
                    value={values.adminName}
                    onChange={handleChange}
                    error={touched.adminName && Boolean(errors.adminName)}
                    helperText={touched.adminName && errors.adminName}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="adminEmail"
                    name="adminEmail"
                    label="店管郵件"
                    fullWidth
                    value={values.adminEmail}
                    onChange={handleChange}
                    error={touched.adminEmail && Boolean(errors.adminEmail)}
                    helperText={touched.adminEmail && errors.adminEmail}
                  />
                  <Field
                    as={FormControlLabel}
                    control={<Checkbox id="adminEnabled" name="adminEnabled" />}
                    label="啟用"
                    checked={values.adminEnabled}
                    onChange={handleChange}
                  />
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      修改
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
