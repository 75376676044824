import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import MaterialTable, { MTableBody } from 'material-table';
import { TABLE_ICONS } from '../../../../@jumbo/constants/TableIcons';
import apiService from 'services/apiService';
import LocalStorageService from 'services/LocalStorageService';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import twLocale from 'date-fns/locale/zh-TW';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.order.list'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  const [data, setData] = React.useState({
    orderFrom: new Date(),
    orderTo: new Date(),
  });
  const [state, setState] = React.useState({
    parentsColumns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '訂單編號', field: 'paymentId' },
      { title: '帳號', field: 'storeAdmin.adminName' },
      { title: '名稱', field: 'ad.name' },
      { title: '銷售日期', field: 'orderTime', type: 'datetime' },
      {
        title: '訂單來源',
        field: 'paymentType',
        render: rowData => (rowData.paymentType === 'CART' ? '購物車' : '自訂付款'),
      },
      {
        title: '付款狀態',
        field: 'paymentStatus',
        render: rowData => (rowData.paymentStatus === 'SUCCESS' ? '已付款' : '尚未付款'),
      },
      {
        title: '總金額',
        field: 'orderAmount',
        type: 'currency',
        cellStyle: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
      },
    ],
    childColumns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '商品代碼', field: 'oiProdCode' },
      { title: '商品名稱', field: 'oiProdName' },
      { title: '商品購買數量', field: 'oiProdSaleQty', type: 'numeric' },
      { title: '商品單價', field: 'oiProdSalePrice', type: 'currency' },
      {
        title: '商品總額',
        type: 'currency',
        render: rowData => '$' + rowData.oiProdSalePrice * rowData.oiProdSaleQty,
      },
    ],
  });
  const [orderList, setOrderList] = React.useState([]);
  const [filteredOrderList, setFilteredOrderList] = React.useState([]);
  React.useEffect(() => {
    getOrderData();
  }, []);
  const getOrderData = () => {
    const storeCode = LocalStorageService.getStoreCode();
    const token = LocalStorageService.getToken();
    apiService.Order.list(storeCode).then(response => {
      apiService.log.add(
        storeCode,
        {
          requestUrl: `/order/history/list/${storeCode}`,
          requestMethod: 'POST',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        //console.log('response', response.data.orderList);
        setOrderList(response.data.orderList);
      } else {
        console.log('orderList', 'order/list response:' + JSON.stringify(response.data));
      }
    });
  };
  // 篩選過濾
  const handleFilterOnSubmit = async values => {
    // console.log('values', values)
    // 過濾時間
    let orderFilteredByTime = orderList.filter(order => {
      const orderTimestamp = Date.parse(order.orderTime);
      return (
        orderTimestamp >= new Date(values.orderFrom).setHours(0, 0, 0, 0) &&
        orderTimestamp <= new Date(values.orderTo).setHours(23, 59, 59, 999)
      );
    });
    // console.log('orderFilteredByTime',orderFilteredByTime)
    if (orderFilteredByTime.length === 0) alert('未發現符合條件會員，請重新搜尋');
    setFilteredOrderList(orderFilteredByTime);
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.order.list" />} breadcrumbs={breadcrumbs}>
      {/*<Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
          width: 600,
        }}>
        <Typography variant="h2" align="center">
          篩選
        </Typography>
        <Formik initialValues={data} onSubmit={handleFilterOnSubmit}>
          {props => {
            const { values, dirty, setFieldValue, isSubmitting, handleChange, handleSubmit, handleReset } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={twLocale}>
                      <DatePicker
                        margin="dense"
                        id="orderFrom"
                        name="orderFrom"
                        label="開始時間"
                        value={values.orderFrom}
                        onChange={val => {
                          // console.log('picked activity start time', val.format());
                          setFieldValue('orderFrom', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DatePicker
                        margin="dense"
                        id="orderTo"
                        name="orderTo"
                        label="結束時間"
                        value={values.orderTo}
                        onChange={val => {
                          // console.log('picked activity start time', val.format());
                          setFieldValue('orderTo', val);
                        }}
                        renderInput={params => <TextField {...params} />}
                      />
                    </MuiPickersUtilsProvider>
                    <Button type="submit" color="primary" size="large" disabled={isSubmitting}>
                      搜尋
                    </Button>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      size="large"
                      onClick={() => {
                        handleReset();
                        // setFilteredMember([]);
                      }}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>*/}
      <MaterialTable
        className={classes.root}
        icons={TABLE_ICONS}
        title="記錄列表"
        columns={state.parentsColumns}
        data={filteredOrderList.length > 0 ? filteredOrderList : orderList}
        options={{
          actionsColumnIndex: -1,
          paging: true,
          pageSize: 10, // make initial page size
          emptyRowsWhenPaging: false, // To avoid of having empty rows
          pageSizeOptions: [10, 15, 30, 50], // rows selection options
        }}
        localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="material.table.delete.text" />,
            },
          },
        }}
      />
    </PageContainer>
  );
}
/*
        detailPanel={rowData => {
          return (
            <MaterialTable
              icons={TABLE_ICONS}
              title="購物清單"
              columns={state.childColumns}
              data={rowData.orderItemList}
              options={{
                headerStyle: {
                  textAlign: 'center',
                  backgroundColor: 'royalblue',
                  color: 'white',
                },
                cellStyle: {
                  textAlign: 'center',
                },
              }}
              style={{
                backgroundColor: 'aliceblue',
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: <IntlMessages id="material.table.delete.text" />,
                  },
                },
              }}
            />
          );
        }}
        components={{
          Body: props => {
            let totalObj = {
              actualSum: 0,
            };
            props.renderData.forEach(rowData => {
              totalObj.actualSum += parseInt(rowData.orderAmount);
            });
            return (
              <div>
                <MTableBody {...props} />
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell colSpan={3} />
                    <TableCell
                      colSpan={4}
                      align="right"
                      style={{
                        fontSize: '1rem',
                      }}>
                      總計金額: ${totalObj.actualSum}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </div>
            );
          },
        }}
*/
