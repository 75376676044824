/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  gameId: null,
  storeBelong: null,
};
export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, setRowData, updateContent, ...others } = props;
  const [gameBelongData, setGameBelongData] = React.useState(initialValues);

  useEffect(() => {
    if (rowData !== null && rowData.storeCode !== undefined) {
      // console.log('rowData', rowData)
      setGameBelongData({
        gameId: rowData.gameId,
        storeBelong: rowData.storeBelong,
      });
    }
  }, [rowData]);

  const handleClose = () => {
    setIsDialogOpen(false);
    setGameBelongData(initialValues);
    setRowData('');
  };
  const handleOnSubmit = values => {
    console.log('values', values);
    values.storeName = rowData.storeName;
    updateContent(rowData.id, values);
    setIsDialogOpen(false);
  };
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>商家遊戲、所屬專案設定</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={gameBelongData} onSubmit={handleOnSubmit}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                setFieldValue,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field required as={FormControl} margin="dense" fullWidth>
                    <InputLabel id="game-id">使用遊戲</InputLabel>
                    <Select
                      labelId="game-id"
                      id="gameId"
                      name="gameId"
                      value={values.gameId ? values.gameId : 'null'}
                      label="遊戲"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={e => {
                        setFieldValue('gameId', e.target.value);
                      }}>
                      <MenuItem value={null}>不使用AR小遊戲</MenuItem>
                      <MenuItem value={1}>飢餓鳥</MenuItem>
                      <MenuItem value={2}>飛鷹之戰</MenuItem>
                      <MenuItem value={3}>迷你高爾夫</MenuItem>
                      <MenuItem value={4}>Fruit射擊戰</MenuItem>
                    </Select>
                  </Field>
                  <Field required as={FormControl} margin="dense" fullWidth>
                    <InputLabel id="projectBelong">歸屬專案</InputLabel>
                    <Select
                      labelId="projectBelong"
                      id="storeBelong"
                      name="storeBelong"
                      value={values.storeBelong ? values.storeBelong : 0}
                      label="歸屬專案"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={e => {
                        setFieldValue('storeBelong', e.target.value);
                      }}>
                      <MenuItem value={0}>未設定</MenuItem>
                      <MenuItem value={1}>會員卡</MenuItem>
                      <MenuItem value={2}>TV</MenuItem>
                      <MenuItem value={3}>TV+會員卡</MenuItem>
                    </Select>
                  </Field>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
