import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import CmcDataByVending from './CmsDataByVending';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.chart.vending'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  return (
    <PageContainer heading={<IntlMessages id="pages.chart.vending" />} breadcrumbs={breadcrumbs}>
      <CmcDataByVending />
    </PageContainer>
  );
}
