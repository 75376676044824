import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  return (
    <PageContainer heading={<IntlMessages id="pages.store.account.create" />} breadcrumbs={breadcrumbs}>
      <Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
        }}>
        <div className="container">
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="storeId"
                type="hidden"
                placeholder=""
                data-sb-validations="required"
                name="storeId"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="adminAccount"
                type="text"
                placeholder="請輸入店管帳號"
                data-sb-validations="required"
                name="adminAccount"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="adminPasswd"
                type="password"
                placeholder="請輸入店管密碼"
                data-sb-validations="required"
                name="adminPasswd"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="adminPasswdConfirm"
                type="password"
                placeholder="請再次輸入店管密碼"
                data-sb-validations="required"
                name="adminPasswdConfirm"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="adminName"
                type="text"
                placeholder="請輸入店管名稱"
                data-sb-validations="required"
                name="adminName"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-control"
                id="adminEmail"
                type="text"
                placeholder="請輸入店管郵件"
                data-sb-validations="required"
                name="adminEmail"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
            </div>
          </div>
          <div className="row align-items-stretch mb-2">
            <div className="col-md-12">
              <input
                className="form-check-input"
                id="enableAccount"
                type="radio"
                placeholder=""
                data-sb-validations="required"
                name="adminEnabled"
                value="enable"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
              <label className="mr-3" htmlFor="adminEnabled">
                啟用
              </label>
              <input
                className="form-check-input"
                id="disableAccount"
                type="radio"
                placeholder=""
                data-sb-validations="required"
                name="adminEnabled"
                value="disable"
                // onChange={this.props.onChange}
                // onBlur={this.props.onBlur}
                // value={this.props.value}
                // onChange={e => this.props.onChange(e)}
              />
              <label htmlFor="adminEnabled">不啟用</label>
            </div>
          </div>
          <div className="row align-items-stretch mb-2 mt-3">
            <div className="col-md-12">
              <button className="btn btn-primary mr-2">{<IntlMessages id="button.store.account.create" />}</button>
              <button className="btn btn-outline-secondary">{<IntlMessages id="button.store.account.cancel" />}</button>
            </div>
          </div>
        </div>
      </Box>
    </PageContainer>
  );
}
