import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import MaterialTable from 'material-table';
import FormikControl from '../../../Components/Formik/FormikControl';
import { Button, Box, Grid, InputLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Formik, ErrorMessage } from 'formik';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { zhTW } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
//import '../../../../assets/css/react-day-picker.css'; //改用inline style
import moment from 'moment';

// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1, width: '3%' },
    { title: '車種', field: 'vehicle.codeVehicleType.name', width: '15%' },
    { title: '車號', field: 'vehicle.no', width: '15%' },
    { title: '年', field: 'year', width: '15%' },
    { title: '月', field: 'month', width: '15%' },
    {
      title: '狀態',
      width: '40%',
      render: rowData => {
        let disabledDays = []; //offline的日期
        let bookedDays = []; //有排adId的日期
        for (let i = 1; i <= 31; i++) {
          if (rowData[`day${i}`]['code'] == 'offline') {
            disabledDays.push(new Date(rowData.year, rowData.month - 1, i));
          } else if (!vehicleStatus.includes(rowData[`day${i}`]['code'])) {
            bookedDays.push(new Date(rowData.year, rowData.month - 1, i));
          }
        }
        return (
          <>
            <style>{`.rdp { --rdp-cell-size: 20px; font-size: 10px; } .rdp-caption_label { font-size: 10px; font-weight: bold; }`}</style>
            <DayPicker
              disableNavigation
              locale={zhTW}
              numberOfMonths={1}
              defaultMonth={new Date(rowData.year, rowData.month - 1, 1)}
              mode="single"
              disabled={disabledDays}
              modifiers={{ booked: bookedDays }}
              modifiersStyles={{ booked: bookedStyle }}
            />
          </>
        );
      },
    },
  ],
};
const vehicleStatus = [null, '', 'offline', 'online', 'tt_avail', 'tt_reserv', 'tt_unavail'];
const bookedStyle = { border: '2px solid blue' };

const useStyles = makeStyles(theme => ({
  sectionValidErrMsg: {
    color: 'red',
  },
}));

export default function DataTable({ timetableList, getTimetableList, vehicleList, codename }) {
  const history = useHistory();
  const classes = useStyles();
  //下拉選單
  const selectVehicleType = [{ key: '請選擇...', value: 0 }];
  const selectVehicle = [{ key: '請選擇...', value: 0 }];
  const initialValues = {
    vehicleType: 0,
    vehicle: 0,
  };
  if (vehicleList) {
    vehicleList.forEach(item => {
      selectVehicle.push({ key: `${item.no} (${item.vehicleType.name})`, value: item.id });
    });
  }
  if (codename && codename.codeVehicleType) {
    codename.codeVehicleType.forEach(item => {
      selectVehicleType.push({ key: item.name, value: item.code });
    });
  }
  //console.log('timetableList',timetableList);
  //查詢欄位
  const [vehicle, setVehicle] = React.useState(0);
  const [vehicleType, setVehicleType] = React.useState(0);
  const [startYearMon, setStartYearMon] = React.useState(null);
  const [stopYearMon, setStopYearMon] = React.useState(null);
  const handleStartDateChange = date => {
    setStartYearMon(date);
  };
  const handleStopDateChange = date => {
    setStopYearMon(date);
  };
  const handleFilterReset = () => {
    //重設
    setVehicle(0);
    setVehicleType(0);
    setStartYearMon(null);
    setStopYearMon(null);
  };
  // 篩選過濾
  const [filteredTimetableList, setFilteredTimetableList] = React.useState([]);
  const handleFilterOnSubmit = async values => {
    let timetableFiltered = timetableList; //查詢結果List
    if (vehicle != 0) {
      timetableFiltered = timetableFiltered.filter(tt => {
        return tt.vehicle.id === vehicle;
      });
    }
    if (vehicleType != 0) {
      timetableFiltered = timetableFiltered.filter(tt => {
        return tt.vehicle.codeVehicleType.code === vehicleType;
      });
    }
    if (startYearMon) {
      timetableFiltered = timetableFiltered.filter(tt => {
        return tt.year + tt.month >= moment(startYearMon).format('YYYYMM');
      });
    }
    if (stopYearMon) {
      timetableFiltered = timetableFiltered.filter(tt => {
        return tt.year + tt.month <= moment(stopYearMon).format('YYYYMM');
      });
    }
    if (timetableFiltered.length === 0) {
      alert('未發現符合條件，請重新搜尋');
    } else {
      setFilteredTimetableList(timetableFiltered);
    }
  };

  // 刪除Timetable API
  const removeTimetable = async rowData => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Timetable.delete(rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/timetable/delete`, token, res.status);
    });
    getTimetableList();
  };

  // 編輯按鈕
  const actions = [
    {
      icon: () => (
        <Button variant="outlined" color="primary">
          新增車輛班表
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增車輛班表',
      onClick: (event, rowData) => {
        history.push(`/timetable/edit?id=`);
      },
    },
    rowData => ({
      icon: () => <TABLE_ICONS.Edit />,
      tooltip: '編輯車輛班表',
      onClick: () => {
        history.push(`/timetable/edit?id=${rowData.id}`);
      },
    }),
  ];

  return (
    <dir>
      <Box
        sx={{
          backgroundColor: '#FFF',
          padding: '10px',
          boxShadow: '0px 0px 5px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
          width: '100%',
        }}>
        <Formik initialValues={initialValues} onSubmit={handleFilterOnSubmit}>
          {formik => {
            //console.log('formik', formik);
            const { values, dirty, setFieldValue, isSubmitting, handleChange, handleSubmit, handleReset } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <InputLabel id="lbVehicleType">車種：</InputLabel>
                    <ErrorMessage name="vehicleType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicleType"
                      value={vehicleType}
                      fullWidth
                      options={selectVehicleType}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={event => setVehicleType(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel id="lbVehicle">車號：</InputLabel>
                    <ErrorMessage name="vehicle" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicle"
                      value={vehicle}
                      fullWidth
                      options={selectVehicle}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={event => setVehicle(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel id="lbStartYearMon">開始年月：</InputLabel>
                    <ErrorMessage name="startYearMon" component="div" className={classes.sectionValidErrMsg} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM"
                          id="startYearMon"
                          value={startYearMon}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel id="lbStopYearMon">結束年月：</InputLabel>
                    <ErrorMessage name="stopYearMon" component="div" className={classes.sectionValidErrMsg} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM"
                          id="stopYearMon"
                          value={stopYearMon}
                          onChange={handleStopDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="lbButton">&nbsp;</InputLabel>
                    <Button variant="outlined" color="primary" type="submit" disabled={isSubmitting}>
                      搜尋
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        handleFilterReset();
                      }}>
                      清除
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
      <div>&nbsp;</div>
      <MaterialTable
        title=""
        icons={TABLE_ICONS}
        columns={columns.parentsColoumns}
        data={filteredTimetableList.length > 0 ? filteredTimetableList : timetableList}
        options={{
          actionsColumnIndex: -1,
          filtering: false,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="material.table.delete.text" />,
            },
          },
        }}
        actions={actions}
        editable={{
          onRowDelete: rowData => removeTimetable(rowData),
        }}
      />
    </dir>
  );
}
