import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.ad.list'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [ad, setAd] = React.useState();
  React.useEffect(() => {
    getAd();
  }, []);

  const getAd = async () => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    if (storeId) {
      await ApiService.Ad.query(storeId)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/ad/query/${storeCode}`, token, response.status);
          //console.log("adlist:",JSON.stringify(response.data.adList));
          response.data.adList.forEach(item => {
            let arrayDate = item.broadcastDate ? item.broadcastDate.split(',') : [];
            if (arrayDate.length > 0) {
              item.broadcastDate = arrayDate[0] + ' (' + item.broadcastDate.split(',').length + '日)';
            } else {
              item.broadcastDate = '(0日)';
            }
          });
          setAd(response.data.adList);
        })
        .catch(async err => {
          console.log('err', err);
          /*if (err.response.status === 401) {
            const res = await refreshToken(); //更新token
            console.log('refreshToken:', res);
          }*/
        });
    }
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.ad.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable ad={ad} getAd={getAd} />
      </Box>
    </PageContainer>
  );
}
