import React from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PostAdd, InsertChart, ImportContacts, Group, Domain, LibraryBooks, LibraryAdd } from '@material-ui/icons';

//import SidebarButtons from '@jumbo/components/AppLayout/partials/SideBar/SIdebarButtons';
import { Divider, CardMedia } from '@material-ui/core';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.firstPage'} />, isActive: true },
];
const buttons = [
  /*{
    title: {
      text: <IntlMessages id="sidebar.playlist.list" />,
      color: '#384CC4',
    },
    icon: <LibraryBooks />,
    backgroundColor: '#EAEDFF',
    link: '/playlist/list',
  },
  {
    title: {
      text: <IntlMessages id="sidebar.ad.list" />,
      color: '#5D9405',
    },
    icon: <LibraryBooks />,
    backgroundColor: '#D7F5B1',
    link: '/ad/list',
  },*/
];
const FirstPage = () => {
  return (
    <PageContainer heading={<IntlMessages id="pages.firstPage" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}>
            <h3>{<IntlMessages id="pages.title" />}</h3>
            <CardMedia component="img" src="/images/welcome.png" />
            <List /*className={classes.list}*/ disablePadding>
              {buttons.map((button, index) => (
                <ListItem
                  key={index}
                  alignItems="flex-start"
                  button
                  component="a"
                  href={button.link}
                  target="" /*_blank*/
                  style={{
                    backgroundColor: button.backgroundColor,
                    color: button.title.color,
                  }}
                  /*className={classes.listItem}*/
                >
                  <ListItemIcon style={{ color: button.title.color }}>{button.icon}</ListItemIcon>
                  <ListItemText
                    primary={button.title.text}
                    secondary={button.subTitle ? button.subTitle.text : ''}
                    secondaryTypographyProps={{
                      style: button.subTitle ? { color: button.subTitle.color } : {},
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default FirstPage;
