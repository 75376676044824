import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import IntlMessages from '@jumbo/utils/IntlMessages';
import FormikContainer from './FormikContainer';
import qs from 'qs';
import 'react-day-picker/dist/style.css';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  {
    label: <IntlMessages id={'pages.vending.streaming.list'} />,
    link: '/vending/streaming/list',
  },
  { label: <IntlMessages id={'pages.vending.streaming.edit'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
}));

export default function Table() {
  const classes = useStyles();
  const [vendingStreaming, setVendingStreaming] = React.useState();
  const qstrobj = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const id = qstrobj.id;
  const insStatus = id && id != 0 ? false : true;
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  React.useEffect(() => {
    getVendingStreaming();
  }, []);

  const getVendingStreaming = async () => {
    if (id) {
      await ApiService.VendingStreaming.query(id)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/vending/streaming/query`, token, response.status);
          if (response.data.vendingStreamingList.length > 0) {
            setVendingStreaming(response.data.vendingStreamingList[0]);
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  return (
    <PageContainer
      heading={id ? <IntlMessages id="pages.vending.streaming.edit" /> : <IntlMessages id="pages.vending.streaming.new" />}
      breadcrumbs={breadcrumbs}>
      <Box className={classes.sectionBoard}>
        <FormikContainer rowData={vendingStreaming} insStatus={insStatus} />
      </Box>
    </PageContainer>
  );
}
