import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import IntlMessages from '@jumbo/utils/IntlMessages';
import FormikContainer from './FormikContainer';
import qs from 'qs';
import 'react-day-picker/dist/style.css';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.ad.list'} />, link: '/ad/list' },
  { label: <IntlMessages id={'pages.ad.edit'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
}));

export default function Table() {
  const classes = useStyles();
  const [codename, setCodename] = React.useState();
  const [adOne, setAdOne] = React.useState();
  const qstrobj = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const adId = qstrobj.id;
  const insStatus = adId && adId != 0 ? false : true;
  const readonly = qstrobj.readonly; //0:全編輯, 1:全唯讀, 2:部分唯讀
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  React.useEffect(() => {
    getCodename();
    getAdOne();
  }, []);

  const getCodename = async () => {
    let codename = {};
    let playlist = {};
    let vehicleRoute = {};
    let game = {};
    await ApiService.Codename.query()
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/codename/query/${storeCode}`, token, response.status);
        codename = response.data;
      })
      .catch(err => console.log('err', err));
    await ApiService.Playlist.query(storeId)
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/playlist/query/${storeCode}`, token, response.status);
        playlist = response.data;
      })
      .catch(err => console.log('err', err));
    await ApiService.VehicleRoute.query()
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/vehicle/route/query/${storeCode}`, token, response.status);
        vehicleRoute = response.data;
      })
      .catch(err => console.log('err', err));
    await ApiService.Games.query()
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/game/query/${storeCode}`, token, response.status);
        game = response.data;
      })
      .catch(err => console.log('err', err));

    setCodename({ ...codename, ...playlist, ...vehicleRoute, ...game });
  };
  const getAdOne = async () => {
    if (storeId && adId) {
      await ApiService.Ad.query(storeId, adId)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/ad/query/${storeCode}`, token, response.status);
          if (adId && response.data.adList.length > 0) {
            setAdOne(response.data.adList[0]);
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  return (
    <PageContainer
      heading={adId ? <IntlMessages id="pages.ad.edit" /> : <IntlMessages id="pages.ad.new" />}
      breadcrumbs={breadcrumbs}>
      <Box className={classes.sectionBoard}>
        <FormikContainer codename={codename} rowData={adOne} insStatus={insStatus} readonly={readonly} />
      </Box>
    </PageContainer>
  );
}
