import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const initialLogData = [['', 0]];
const initialLabelData = [''];
const initialHashTable = {
  12345678: '',
};

const initialFilterDate = {
  start: `${new Date().getFullYear()}-${(new Date().getMonth() - 2).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
  end: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
};
/**函式區 */

// 取得所有商家遊戲遊玩次數 函式
const getAnalysisLog = () =>
  new Promise((resolve, reject) => {
    const token = localStorageService.getToken();
    ApiService.Game.recordsAll(token)
      .then(response => {
        const result = {
          labels: [],
          data: [],
          countTotal: response.data.countTotal,
        };
        response.data.gameRecords.map(item => {
          result.data.push(Object.values(item));
          result.labels.push(item.date);
        });
        // console.log('result', result)
        resolve(result);
      })
      .catch(err => reject(err));
  });
/** hash 函式
 * x 軸labels(時間)轉為timestamp的hash table
 * @param {Array} labels
 * @returns
 */
const hashfn = labels => {
  let hashTable = {};
  labels.map(label => {
    hashTable[new Date(label).setHours(0, 0, 0, 0)] = label;
  });
  return hashTable;
};
// x軸label過濾(時間)
const labelFilter = (labels, startTime, endTime) =>
  new Promise((resolve, reject) => {
    // x 時間軸filter
    let filteredDateLabel = labels
      .map(label => new Date(label).setHours(0, 0, 0, 0))
      .filter(item => item >= startTime && item <= endTime);
    resolve(filteredDateLabel);
  });
// 資料隨著時間過濾
const dataFilter = (logData, startTime, endTime) =>
  new Promise((resolve, reject) => {
    const result = { count: 0, data: {} };
    const filteredData = logData.filter(item => {
      // 此層結構 => ['21/Oct/2022', 8]
      const timestamp = new Date(item[0]).setHours(0, 0, 0, 0);
      return timestamp >= startTime && timestamp <= endTime;
    });
    // 計算總次數
    const countTotal = filteredData.reduce((accumulator, currentValue, currentIndex, array) => {
      return accumulator + currentValue[1];
    }, 0);
    result.data = filteredData;
    result.count = countTotal;
    // console.log('filteredData',result)
    resolve(result);
  });

// chart.js 設定config
const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '使用者AR遊戲曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function Table() {
  const classes = useStyles();
  const [labels, setLebels] = React.useState(initialLabelData);
  const [logdata, setLogData] = React.useState(initialLogData);
  const [countTotal, setCountTotal] = React.useState(0);
  const [filteredLabel, setFilteredLabel] = React.useState(initialLabelData);
  const [filteredLogdata, setFilteredLogData] = React.useState(initialLogData);
  const [filteredCountTotal, setFilteredCountTotal] = React.useState(0);
  const [hashTable, setHashTable] = React.useState(initialHashTable);

  const data = {
    labels: filteredLabel,
    datasets: [
      {
        label: 'AR小遊戲資訊',
        data: filteredLogdata,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  React.useEffect(() => {
    getAnalysisLog()
      .then(result => {
        setCountTotal(result.countTotal);
        setFilteredCountTotal(result.countTotal);
        setLebels(result.labels);
        setFilteredLabel(result.labels);
        setLogData(result.data);
        setFilteredLogData(result.data);
        const labelHashTable = hashfn(result.labels);
        setHashTable(labelHashTable);
      })
      .catch(error => {
        // 出現錯誤回復初始設定值
        setFilteredLabel(initialLabelData);
        setFilteredLogData(initialLogData);
        setLebels(initialLabelData);
        setLogData(initialLogData);
        setFilterDate(initialFilterDate);
        setFilteredCountTotal(0);
      });
  }, []);
  // 日期輸入
  const [filterDate, setFilterDate] = React.useState(initialFilterDate);
  const handleStartChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, start: event.target.value }));
  };
  const handleEndChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, end: event.target.value }));
  };
  // 過濾搜尋
  const handleClick = event => {
    event.preventDefault();
    const startTime = new Date(filterDate.start).setHours(0, 0, 0, 0);
    const endTime = new Date(filterDate.end).setHours(0, 0, 0, 0);
    labelFilter(labels, startTime, endTime).then(res => {
      const filteredDateLabel = res.map(item => hashTable[item]);
      setFilteredLabel(filteredDateLabel);
    });
    dataFilter(logdata, startTime, endTime).then(res => {
      setFilteredCountTotal(res.count);
      setFilteredLogData(res.data);
    });
  };

  // 清除
  const handleClickReset = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    setFilterDate(initialFilterDate);
    setFilteredCountTotal(countTotal);
  };
  return (
    <Box className={classes.section} style={{ height: '300px', paddingBottom: '150px' }}>
      <h3>AR遊戲-所有使用者遊玩次數 共:{filteredCountTotal}</h3>
      <p>
        開始時間: {filterDate.start} 結束時間: {filterDate.end}
      </p>
      <input type="date" id="start" name="start" onChange={handleStartChange} value={filterDate.start} />
      <input type="date" id="end" name="end" onChange={handleEndChange} value={filterDate.end} />

      <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClick}>
        過濾
      </Button>
      <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickReset}>
        清除
      </Button>
      <Line data={data} width={600} height={400} options={options} />
    </Box>
  );
}
