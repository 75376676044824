import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from '../../../http-common';
import jwt from 'jsonwebtoken';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            // localStorage.setItem('token', data.token.access_token);
            localStorage.setItem('token', data.token.accessToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.accessToken;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.accessToken));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  // onLogin: ({ account, password }) => {
  onLogin: ({ adminAccount, adminPasswd }) => {
    //console.log('adminAccount', adminAccount, 'adminPasswd', adminPasswd);
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('auth/signin', {
            adminAccount: adminAccount,
            adminPasswd: adminPasswd,
          })
          .then(({ data }) => {
            //console.log('SignIn Restule', data);
            if (data) {
              // localStorage.setItem('token', data.token.access_token);
              localStorage.setItem('token', data.accessToken);
              localStorage.setItem('refreshToken', data.refreshToken);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;

              //const decode = jwt.decode(data.accessToken, { complete: true });
              //console.log('authUser getAuthUser decode', decode);

              dispatch(fetchSuccess());
              dispatch(setAuthUser(data));
              // dispatch(JWTAuth.getAuthUser(true, data.accessToken));  // 20220422

              // 以下4行測試用，待刪
              // const user = { name: 'Admin', email: 'admin1@gmail.com', password: password };
              // dispatch(fetchSuccess());
              // localStorage.setItem('user', JSON.stringify(user));
              // dispatch(setAuthUser(user));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .delete('auth/signout')
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('auth/me')
        .then(({ data }) => {
          console.log('authUser getAuthUser auth/me');
          if (data) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
