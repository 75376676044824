import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import ApiService from 'services/apiService';
import http from '../../../http-common';
import { GoogleMap, KmlLayer, withGoogleMap, withScriptjs } from 'react-google-maps';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MapDialog({ routeId, disabled }) {
  const [open, setOpen] = React.useState(false);
  const [route, setRoute] = React.useState('');
  const handleClickOpen = () => {
    getVehicleRoute(routeId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getVehicleRoute = async id => {
    await ApiService.VehicleRoute.query(id)
      .then(async res => {
        if (res.data.routeList && res.data.routeList.length > 0) {
          setRoute(res.data.routeList[0]);
        }
      })
      .catch(err => console.log('err', err));
  };

  // 地圖資料
  var rand = Math.random();
  var rurl = http.defaults.kmlURL + '/vehicle/route/' + (routeId ? routeId : '0') + '/kml?' + rand;
  var center = { lat: 25.04693832778084, lng: 121.51743252137143 }; //預設:台北車站
  if (route && route.route) {
    //開始點設定中心
    var xml2js = require('xml2js');
    var parser = new xml2js.Parser();
    parser.parseString(route.route, function(err, result) {
      var xmlAdStart = result.kml.Document[0].Placemark[1].Point[0].coordinates[0];
      if (xmlAdStart) {
        var startLoc = xmlAdStart.split(',');
        if (startLoc.length > 1) {
          center = {
            lng: parseFloat(startLoc[0]),
            lat: parseFloat(startLoc[1]),
          };
        }
      }
    });
  }

  const KmlLayerExampleGoogleMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap defaultZoom={14} defaultCenter={center}>
        <KmlLayer url={rurl} options={{ preserveViewport: true }} />
      </GoogleMap>
    )),
  );

  return (
    <Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>路線預覽</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText />
          <KmlLayerExampleGoogleMap
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC-8SButSVhDGMJHNoQzgNcitOKrGz_pSc&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<TABLE_ICONS.MapIcon />}
        disabled={disabled}>
        路線預覽
      </Button>
    </Box>
  );
}
