import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.effect-game.list'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [adRecord, setAdRecord] = React.useState();
  React.useEffect(() => {
    getAdRecord();
  }, []);

  const getAdRecord = async () => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    if (storeId) {
      await ApiService.AdRecord.queryEffect(storeId, 'game') //moveType like %play%
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/effect/game/query/${storeCode}`, token, response.status);
          response.data.adBroadcastRecordList.forEach(item => {
            let arrayDate = item.adBroadcastDate ? item.adBroadcastDate.split(',') : [];
            if (arrayDate.length > 0) {
              item.adName =
                item.adName + ' (' + (parseInt(arrayDate.indexOf(item.broadcastDate)) + 1) + '/' + arrayDate.length + ')';
            }
          });
          setAdRecord(response.data.adBroadcastRecordList);
        })
        .catch(async err => {
          console.log('err', err);
          /*if (err.response.status === 401) {
            const res = await refreshToken(); //更新token
            console.log('refreshToken:', res);
          }*/
        });
    }
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.effect-game.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable adRecord={adRecord} getAdRecord={getAdRecord} />
      </Box>
    </PageContainer>
  );
}
