import axios from '../http-common';
import jwt from 'jsonwebtoken';

export const isAccessTokenValid = async token => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  let result = {};
  await axios
    .post('auth/isAccessTokenValid')
    .then(({ data }) => {
      if (data) {
        //console.log('isAccessTokenValid Result', data);
        result.isValid = true;
      } else {
        return false;
      }
    })
    .catch(function(error) {
      //console.log('error', error);
      //console.log('JWT / index / token is not valid');
      result.isValid = false;
    });
  return result;
};

export const refreshToken = async () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  let result = {};
  await axios
    .post('auth/refreshtoken', {
      refreshToken: refreshToken,
    })
    .then(async ({ data }) => {
      //console.log('refreshToken Result', data);
      result.accessToken = data.accessToken;
      result.refreshToken = data.refreshToken;
      if (data) {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;

        //const decode = jwt.decode(data.accessToken, { complete: true });
        //console.log('authUser getAuthUser decode', decode);
        //console.log('get new accessToken successfully! ! !');
      } else {
        //console.log(data.err);
        result.err = data.err;
      }
    })
    .catch(function(error) {
      //console.log(error);
      result.err = error;
    });
  return result;
};
