import React from 'react';
import { GoogleMap, KmlLayer, withGoogleMap, withScriptjs } from 'react-google-maps';
import { Box } from '@material-ui/core';

var rand = Math.random(99999);
var rurl = 'https://ubobee.com/test.kml?' + rand;

const KmlLayerExampleGoogleMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: 25.053873, lng: 121.544175 }}>
      {/* lat: 41.876, lng: -87.624   lat: 25.0468026, lng: 121.2922112
    <KmlLayer url="https://googlearchive.github.io/js-v2-samples/ggeoxml/cta.kml" options={{ preserveViewport: true }} />
    */}
      <KmlLayer url={rurl} options={{ preserveViewport: true }} />
    </GoogleMap>
  )),
);

/*
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */
const MapKmLayerExample = () => {
  return (
    <KmlLayerExampleGoogleMap
      isMarkerShown
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC-8SButSVhDGMJHNoQzgNcitOKrGz_pSc&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<Box height={1} />}
      containerElement={<Box height={{ xs: 300, sm: 400 }} />}
      mapElement={<Box height={1} />}
    />
  );
};

export default MapKmLayerExample;
