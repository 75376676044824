import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataChart from './DataChart';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';
import qs from 'qs';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  {
    label: <IntlMessages id={'pages.effect-ad.list'} />,
    link: '/effect-ad/list',
  },
  { label: <IntlMessages id={'pages.effect-ad.detail'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [effectAdChart, setEffectAdChart] = React.useState();
  const [effectAdDetail, setEffectAdDetail] = React.useState();
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  const qstrobj = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const recordId = qstrobj.recordId;
  const adId = qstrobj.adId;

  React.useEffect(() => {
    getEffectAdDetail();
  }, []);

  const getEffectAdDetail = async () => {
    if (storeId && adId) {
      await ApiService.AdRecord.detailEffectAd(storeId, adId, recordId)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/effect/ad/detail/${storeCode}`, token, response.status);
          setEffectAdDetail(response.data.effectAdDetailList);
          setEffectAdChart(response.data.effectAdChart);
        })
        .catch(err => console.log('err', err));
    }
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.adrecord.route" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataChart effectAdChart={effectAdChart} />
        <DataTable effectAdDetail={effectAdDetail} getEffectAdDetail={getEffectAdDetail} />
      </Box>
    </PageContainer>
  );
}
