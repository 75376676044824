import {
  CREATE_STORE_ADMIN,
  CREATE_STORE_FIRST_ADMIN,
  UPDATE_STORE_FIRST_ADMIN,
  RETRIEVE_STORE_ADMINS,
  UPDATE_STORE_ADMIN,
  DELETE_STORE_ADMIN,
  DELETE_ALL_STORE_ADMINS,
} from '../../@jumbo/constants/ActionTypes';

import storeAdminDataService from '../../services/StoreAdminService';
import commonService from '../../services/CommonService';
import ApiService from 'services/apiService';
import LocalStorageService from 'services/LocalStorageService';

export const createStoreAdmin = (
  storeCode,
  adminAccount,
  adminPasswd,
  adminName,
  adminEmail,
  adminEnabled,
) => async dispatch => {
  try {
    const res = await storeAdminDataService.create(storeCode, {
      adminAccount,
      adminPasswd,
      adminName,
      adminEmail,
      adminEnabled,
    });
    const token = LocalStorageService.getToken();
    //await ApiService.log.addEasy(storeCode, `/storeAdmin/${storeCode}`, token, res.status); //POST
    await ApiService.log.add(
      storeCode,
      {
        requestUrl: `/storeAdmin/${storeCode}`,
        requestMethod: 'POST',
        responseStatus: res.status,
      },
      token,
    );

    dispatch({
      type: CREATE_STORE_ADMIN,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createStoreFirstAdmin = storeAdminData => async dispatch => {
  try {
    const res = await storeAdminDataService.createStoreFirstAdmin(storeAdminData);

    dispatch({
      type: CREATE_STORE_FIRST_ADMIN,
      payload: res.data,
    });
    commonService.popupHttpSuccMessage(res);
    return Promise.resolve(res.data);
  } catch (err) {
    const errMsg = commonService.parseHttpErrorMessage(err);
    console.log('createStoreFirstAdmin errMsg', errMsg);
    commonService.popupMsg(errMsg);
    // return Promise.reject(err);
  }
};

export const updateStoreFirstAdmin = storeAdminData => async dispatch => {
  try {
    const res = await storeAdminDataService.updateStoreFirstAdmin(storeAdminData);

    dispatch({
      type: UPDATE_STORE_FIRST_ADMIN,
      payload: res.data,
    });
    commonService.popupHttpSuccMessage(res);
    return Promise.resolve(res.data);
  } catch (err) {
    const errMsg = commonService.parseHttpErrorMessage(err);
    console.log('updateStoreFirstAdmin errMsg', errMsg);
    commonService.popupMsg(errMsg);
    // return Promise.reject(err);
  }
};

export const retrieveStoreAdmins = storeCode => async dispatch => {
  //page, size
  try {
    const res = await storeAdminDataService.getAllUser(storeCode); //.findAllByPage(page, size); //
    //console.log('retrieveStoreAdmins', res);
    const token = LocalStorageService.getToken();
    //await ApiService.log.addEasy(storeCode, `/storeAdmin/${storeCode}`, token, res.status); //GET
    await ApiService.log.add(
      storeCode,
      {
        requestUrl: `/storeAdmin/${storeCode}`,
        requestMethod: 'GET',
        responseStatus: res.status,
      },
      token,
    );
    dispatch({
      type: RETRIEVE_STORE_ADMINS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateStoreAdmin = (id, data, storeCode) => async dispatch => {
  try {
    await storeAdminDataService.update(id, data);
    const res = await storeAdminDataService.getAllUser(storeCode);
    const token = LocalStorageService.getToken();
    //await ApiService.log.addEasy(storeCode, `/storeAdmin/${id}/${storeCode}`, token, res.status); //PUT
    await ApiService.log.add(
      storeCode,
      {
        requestUrl: `/storeAdmin/${id}/${storeCode}`,
        requestMethod: 'PUT',
        responseStatus: res.status,
      },
      token,
    );
    // const t = performance.now()
    dispatch({
      type: UPDATE_STORE_ADMIN,
      payload: res.data, //data,
    });
    // console.log('Time after dispatching', performance.now() - t)
    //return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    //return Promise.reject(err);
  }
};
// export const updateStoreAdminTest = (id, data) => async dispatch => {
//   await storeAdminDataService.update(id, data)
//   const res = await storeAdminDataService.getAll()
//   console.log('res:', res.data)
//   dispatch({
//     type: UPDATE_STORE_ADMIN,
//     payload:
//   })
// }

export const deleteStoreAdmin = (storeCode, id) => async dispatch => {
  try {
    await storeAdminDataService.remove(id);
    const res = await storeAdminDataService.getAllUser(storeCode);
    const token = LocalStorageService.getToken();
    //await ApiService.log.addEasy(storeCode, `/storeAdmin/${id}/${storeCode}`, token, res.status); //DELETE
    await ApiService.log.add(
      storeCode,
      {
        requestUrl: `/storeAdmin/${id}/${storeCode}`,
        requestMethod: 'DELETE',
        responseStatus: res.status,
      },
      token,
    );
    dispatch({
      type: DELETE_STORE_ADMIN,
      payload: res.data, //{ id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllStoreAdmins = () => async dispatch => {
  try {
    const res = await storeAdminDataService.removeAll();

    dispatch({
      type: DELETE_ALL_STORE_ADMINS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findStoreAdminsByAdminAccount = adminAccount => async dispatch => {
  try {
    const res = await storeAdminDataService.findByAdminAccount(adminAccount);

    dispatch({
      type: RETRIEVE_STORE_ADMINS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
