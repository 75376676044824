import { CREATE_STORE, RETRIEVE_STORES, UPDATE_STORE } from '../../@jumbo/constants/ActionTypes';

const initialState = [];

function storeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_STORE:
      return [...state, payload];

    case RETRIEVE_STORES:
      const newPayload = payload;
      // console.log('payload', payload);
      payload.forEach(p => {
        if (p.storeAdmin && p.storeAdmin.length > 0) {
          p['storeFirstAdminId'] = p.storeAdmin[0].id;
        }
        delete p.storeAdmin;
        // console.log('payload p', p);
      });
      // console.log('payload new', newPayload);
      return newPayload;

    case UPDATE_STORE:
      return payload;
    /*return state.map((storeAdmin) => {
        if (storeAdmin.id === payload.id) {
          return {
            ...storeAdmin,
            ...payload,
          };
        } else {
          return state;
        }
      });*/

    // case DELETE_STORE:
    //   return payload;
    //   // return state.filter(({ id }) => id !== payload.id);

    // case DELETE_ALL_STORES:
    //   return [];

    default:
      return state;
  }
}

export default storeReducer;
