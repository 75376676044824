import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
}));

function FormikContainer(props) {
  let { rowData, device, codename, insStatus } = props;
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
  };
  //選單項目
  const selectDeviceOne = [{ key: '請選擇...', value: 0 }];
  const selectSensorOne = [{ key: '請選擇...', value: 0 }];
  const selectVehicleType = [{ key: '請選擇...', value: 0 }];
  const selectVehicleStatus = [{ key: '請選擇...', value: 0 }];
  //初始值
  const initialValues = {
    vehicleId: '',
    no: '',
    deviceOne: 0,
    sensorOne: 0,
    vehicleType: 0,
    vehicleStatus: 0,
  };
  //console.log('codename:', codename);

  if (device) {
    //設定選單項目
    device.forEach(item => {
      selectDeviceOne.push({
        key: `${item.macAddr} ${item.name}`,
        value: item.macAddr,
      });
      selectSensorOne.push({
        key: `${item.macAddr} ${item.name}`,
        value: item.macAddr,
      });
    });
  }
  if (codename) {
    codename.codeVehicleType.forEach(item => {
      selectVehicleType.push({ key: item.name, value: item.code });
    });
    codename.codeVehicleStatus.forEach(item => {
      selectVehicleStatus.push({ key: item.name, value: item.code });
    });
  }
  //設定初始資料
  if (rowData) {
    //第2次render時才有資料
    initialValues.vehicleId = rowData.id;
    initialValues.no = rowData.no;
    initialValues.deviceOne = rowData.deviceOne && rowData.deviceOne.macAddr ? rowData.deviceOne.macAddr : 0;
    initialValues.sensorOne = rowData.sensorOne && rowData.sensorOne.macAddr ? rowData.sensorOne.macAddr : 0;
    initialValues.vehicleType = rowData.vehicleType && rowData.vehicleType.code ? rowData.vehicleType.code : 0;
    initialValues.vehicleStatus = rowData.vehicleStatus && rowData.vehicleStatus.code ? rowData.vehicleStatus.code : 0;
  }
  // 送出前檢查欄位
  const validationSchema = Yup.object({
    no: Yup.string().required('**必填'),
    vehicleType: Yup.string().min(2, '**必填'),
    vehicleStatus: Yup.string().min(2, '**必填'),
  });

  // 新增 or 更新
  const onSubmit = async formData => {
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let vehicleData = {
      vehicleId: formData.vehicleId,
      no: formData.no,
      deviceOne: formData.deviceOne != 0 ? formData.deviceOne : null,
      sensorOne: formData.sensorOne != 0 ? formData.sensorOne : null,
      vehicleType: formData.vehicleType != 0 ? formData.vehicleType : null,
      vehicleStatus: formData.vehicleStatus != 0 ? formData.vehicleStatus : null,
    };

    if (insStatus) {
      //insStatus=true 新增
      await ApiService.Vehicle.add(vehicleData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/vehicle/add`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      await ApiService.Vehicle.update(vehicleData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/vehicle/update`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    }
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          console.log('formik', formik);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormikControl
                    control="hidden"
                    name="vehicleId"
                    fullWidth
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                  <Box className={classes.section}>
                    <InputLabel id="lbNo">編號*</InputLabel>
                    <ErrorMessage name="no" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl control="input" name="no" fullWidth errors={formik.errors} touched={formik.touched} />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbDeviceOne">播放設備</InputLabel>
                    <ErrorMessage name="deviceOne" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="deviceOne"
                      options={selectDeviceOne}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </Box>
                  {/*<Box className={classes.section}>
                    <InputLabel id="lbSensorOne">感測設備</InputLabel>
                    <ErrorMessage name="sensorOne" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="sensorOne"
                      options={selectSensorOne}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </Box>*/}
                  <Box className={classes.section}>
                    <InputLabel id="lbVehicleType">類型</InputLabel>
                    <ErrorMessage name="vehicleType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicleType"
                      options={selectVehicleType}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbVehicleStatus">狀態</InputLabel>
                    <ErrorMessage name="vehicleStatus" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicleStatus"
                      options={selectVehicleStatus}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit">
                      {insStatus ? '確定新增' : '確定更新'}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[車輛更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
