import axios from 'axios';

let env = process.env.NODE_ENV;
let mode = process.env.REACT_APP_MODE;
//console.log('http-common / NODE_ENV = ' + env);
//console.log('http-common / REACT_APP_MODE = ' + mode);

let config = {
  baseURL: 'http://localhost:8080/api',
  kmlURL: 'https://vmb.membercard.cc/api',
  headers: {
    'Content-type': 'application/json',
  },
};
if (mode == 'aws-eks') {
  config.baseURL = '/api';
} else if (mode == 'aws') {
  config.baseURL = 'https://vmb.membercard.cc/api';
} else if (mode == 'test') {
  config.baseURL = 'https://vmb.agtoptv.com/api';
}
//console.log('http-common / baseURL = ' + config.baseURL);

export default axios.create(config);
