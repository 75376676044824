/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { CrossButton } from '../../../Components/buttonStyle';
import StoreService from 'services/StoreService';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, setRowData, initialValues, getStoreData, ...others } = props;
  const [data, setData] = React.useState(initialValues); //呈現表格用
  const [selectedImage, setSelectedImage] = React.useState({ storeImg: {}, storeBigImg: [], tag: null }); //新照片儲存file用
  const [imageUrl, setImageUrl] = React.useState({ storeImg: '', storeBigImg: [] }); //預覽圖片畫面呈現用
  const [imgName, setImgName] = React.useState({ storeImg: '', storeBigImg: [] }); //更新資料庫用
  // console.log('rowData / edit table', rowData)
  useEffect(() => {
    //將傳進來的rowData分別存進 data, imageUrl, imgName
    // console.log('run first useEffect')
    // console.log('rowData', rowData)
    if (rowData.storeCode !== undefined) {
      //1.存進data 呈現表單資料
      setData({
        ...rowData,
      });
      //2.存進imageUrl, 呈現預覽畫面使用
      setImageUrl({
        ...imageUrl,
        storeImg: rowData.storeImage,
        storeBigImg: rowData.storeBigImage === null ? [] : rowData.storeBigImage,
      });
      //3.存進imgName，分析DB回傳圖片url,抽出檔名。 更新回存DB使用
      let storeImageName_DB = '';
      let storeBigImgName_DB = [];
      if (rowData.storeImage) {
        storeImageName_DB = new URL(rowData.storeImage).pathname.split('/').slice(-1)[0]; //分析DB回傳URL->取得檔名->存字串
      }
      if (rowData.storeBigImage && rowData.storeBigImage.length > 0) {
        storeBigImgName_DB = rowData.storeBigImage.map(bigImgURL => {
          //分析URL->取得檔名
          return new URL(bigImgURL).pathname.split('/').slice(-1)[0];
        });
      }
      setImgName({
        ...imgName,
        storeImg: storeImageName_DB,
        storeBigImg: storeBigImgName_DB,
      });
      // console.log('step 1 / rowData / ', rowData);
      // console.log('step 1 / rowData / set / storeimg URL', rowData.storeImage);
      // console.log('step 1 / rowData / set / storeBigimg URL', rowData.storeBigImage);
      // console.log('step 1 / rowData / set /storeImageName', storeImageName_DB);
      // console.log('step 1 / rowData / set /storeBigImgUrlName', storeBigImgName_DB);
    }
  }, [rowData]);
  useEffect(() => {
    // console.log('selected image', selectedImage);
    if (selectedImage.tag == 0) {
      //tage = 0 代表小圖
      let imgURL = '';
      // console.log(
      //   'step 2/ selectImage change / tag 0 / small image /selectedImage.storeImg is file?',
      //   selectedImage.storeImg instanceof File,
      // );
      if (selectedImage.storeImg instanceof File) {
        imgURL = URL.createObjectURL(selectedImage.storeImg);
        // console.log('step 2/ selectImage change / tag 0 / 產生小圖URL', imgURL);
      } else {
        imgURL = '';
        // console.log('step 2/ selectImage change / 刪除小圖 / 小圖URL / 空', imgURL);
      }
      setImageUrl({
        ...imageUrl,
        storeImg: imgURL,
      });
    } else if (selectedImage.tag == 1) {
      //tage = 1 代表大圖
      //取得selected image url 存進 imgurl
      // console.log('step 2 / add / before / selectedImage', selectedImage);
      // console.log('step 2 / add / before / imageUrl', imageUrl);
      // console.log('step 2 / add / before / imgName', imgName);
      let originImgURL = imageUrl.storeBigImg;
      let ImgURL = [];
      if (originImgURL.length === 0) {
        // console.log('step 3 / img big url / length', imageUrl.storeBigImg.length);
        // console.log('step 3 / imgUrl length = 0 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length = 0 / generating url / before / img Url', imageUrl.storeBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.storeBigImg[0]));
      } else if (originImgURL.length < 4) {
        // console.log('step 3 / img big url / length', imageUrl.storeBigImg.length);
        // console.log('step 3 / imgUrl length < 4 / generating url / selectedImage', selectedImage);
        // console.log('step 3 / imgUrl length < 4 / generating url / before / img Url', imageUrl.storeBigImg);
        ImgURL.push(URL.createObjectURL(selectedImage.storeBigImg.slice(-1)[0]));
      } else {
        alert('最多三張圖');
      }
      originImgURL = originImgURL.concat(ImgURL);
      // console.log('step 4 / img big url / length', originImgURL.length);
      // console.log('step 4 / generating url / after / img Url', originImgURL);
      setImageUrl({
        ...imageUrl,
        storeBigImg: originImgURL,
      });
    }
    if (selectedImage.tag !== null) {
      return () => {
        setSelectedImage(prev => {
          let newState = Object.assign({}, prev); //shallow copy state
          newState.tag = null;
          return newState;
        });
      };
    }
  }, [selectedImage]);
  const resetState = () => {
    setSelectedImage(prev => {
      let newState = Object.assign({}, prev); //shallow copy state
      newState.storeImg = {};
      newState.storeBigImg = [];
      newState.tag = null;
      // console.log('handle close:', newState);
      return newState;
    });
    setImageUrl(prev => {
      let newState = Object.assign({}, prev); //shallow copy of object
      newState.storeImg = '';
      newState.storeBigImg = [];
      return newState;
    });
  };
  const handleClose = () => {
    getStoreData();
    setIsDialogOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    // console.log('[Edit Form Dialog / handle submit]');
    setIsDialogOpen(false);
    let storeImgName = imgName.storeImg;
    let storeBigImgName = imgName.storeBigImg;
    // console.log('submit selected state', selectedImage);
    // console.log('submit imgName state', imgName);
    if (selectedImage.storeImg instanceof File) {
      // console.log('selected image updload');
      await ApiService.Image.upload(storeCode, 'store', selectedImage.storeImg).then(res => {
        storeImgName = res.data.uuidName;
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/store/${storeCode}/upload/store`,
            requestMethod: 'POST',
            responseStatus: res.status,
          },
          token,
        );
        // console.log('產生小圖uuid', storeImgName);
      });
    }
    if (selectedImage.storeBigImg.length > 0) {
      for (let item of selectedImage.storeBigImg) {
        await ApiService.Image.upload(storeCode, 'store', item).then(res => {
          storeBigImgName.push(res.data.uuidName);
          ApiService.log.add(
            storeCode,
            {
              requestUrl: `/store/${storeCode}/upload/store`,
              requestMethod: 'POST',
              responseStatus: res.status,
            },
            token,
          );
        });
      }
    } else {
      storeBigImgName = storeBigImgName;
    }
    setImgName({
      ...imgName,
      storeImg: storeImgName,
      storeBigImg: storeBigImgName,
    });
    let storeData = {
      storeCode: values.storeCode,
      storePhone: values.storePhone,
      storeAddr: values.storeAddr,
      storeAddrLat: values.storeAddrLat ? values.storeAddrLat : 0,
      storeAddrLng: values.storeAddrLng ? values.storeAddrLng : 0,
      storeIntro: values.storeIntro,
      storeService: values.storeService,
      storeReserve: values.storeReserve,
      storeImage: storeImgName,
      storeBigImage: JSON.stringify(storeBigImgName),
      storeWebsite: values.storeWebsite,
      storeLineoa: values.storeLineoa,
      storeFacebook: values.storeFacebook,
      storeProdName: values.storeProdName,
      gameId: values.gameId,
    };
    // console.log('[Edit Form Dialog / handle submit] storeuct data => ', storeData);
    StoreService.updateByStoreCode(storeCode, storeData).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/store/info/${storeCode}`,
          requestMethod: 'PUT',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getStoreData(storeCode);
      }
    });
    resetState();
  };
  const removeImage = (tag, name) => {
    // change selectedImage state
    // console.log('step 2 / remove / before / selectedImage', selectedImage);
    // console.log('step 2 / remove / before / imageUrl', imageUrl);
    // console.log('step 2 / remove / before / imgName', imgName);
    if (tag == 0) {
      //判斷小圖
      //1.清空selectedImage
      setSelectedImage({
        ...selectedImage,
        storeImg: '',
        tag: 0,
      });
      setImgName({
        ...imgName,
        storeImg: '',
      });
    } else if (tag == 1) {
      //判斷大圖
      // console.log(`Delete the big image ${name}`);
      // console.log('判斷大圖', imgName.storeBigImg);
      let index = imgName.storeBigImg.findIndex(item => {
        return item == name;
      });
      // console.log('index', index);
      let store_big_image_splice = imageUrl.storeBigImg;
      store_big_image_splice.splice(index, 1);
      let filtered_name = imgName.storeBigImg.filter((item, index) => {
        // console.log(`index ${index} name => ${item}`);
        return item !== name;
      });

      setImgName({
        ...imgName,
        storeBigImg: filtered_name,
      });
      setImageUrl({
        ...imageUrl,
        storeBigImg: store_big_image_splice,
      });

      // console.log('img name filtered', filtered_name);
      // console.log('img url after', store_big_image_splice);
    }
  };
  const validationSchema = yup.object().shape({
    storeCode: yup.string('Product Code').required('必填欄位'),
    storeName: yup.string('Product Name').required('必填欄位'),
  });
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改商家資訊</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={data} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                setFieldValue,
                isSubmitting,
                handleChange,
                // handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeIntro"
                        name="storeIntro"
                        label="商家介紹"
                        fullWidth
                        multiline
                        value={values.storeIntro}
                        onChange={handleChange}
                        error={touched.storeIntro && Boolean(errors.storeIntro)}
                        helperText={touched.storeIntro && errors.storeIntro}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeService"
                        name="storeService"
                        label="商家服務"
                        fullWidth
                        multiline
                        value={values.storeService}
                        onChange={handleChange}
                        error={touched.storeService && Boolean(errors.storeService)}
                        helperText={touched.storeService && errors.storeService}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storePlace"
                        name="storePlace"
                        label="場地服務"
                        fullWidth
                        value={values.storePlace}
                        onChange={handleChange}
                        error={touched.storePlace && Boolean(errors.storePlace)}
                        helperText={touched.storePlace && errors.storePlace}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeReserve"
                        name="storeReserve"
                        label="預約服務"
                        fullWidth
                        value={values.storeReserve}
                        onChange={handleChange}
                        error={touched.storeReserve && Boolean(errors.storeReserve)}
                        helperText={touched.storeReserve && errors.storeReserve}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="storePhone"
                        name="storePhone"
                        label="商家電話"
                        type="number"
                        fullWidth
                        multiline
                        value={values.storePhone}
                        onChange={handleChange}
                        error={touched.storePhone && Boolean(errors.storePhone)}
                        helperText={touched.storePhone && errors.storePhone}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="storeAddr"
                        name="storeAddr"
                        label="商家地址"
                        type="text"
                        fullWidth
                        value={values.storeAddr}
                        onChange={handleChange}
                        error={touched.storeAddr && Boolean(errors.storeAddr)}
                        helperText={touched.storeAddr && errors.storeAddr}
                      />
                      <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="game-id">遊戲集點</InputLabel>
                        <Select
                          labelId="game-id"
                          id="gameId"
                          name="gameId"
                          value={values.gameId}
                          label="遊戲集點"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            setFieldValue('gameId', e.target.value);
                          }}>
                          {/* <MenuItem value="0">不使用AR小遊戲</MenuItem> */}
                          <MenuItem value="1">飢餓鳥</MenuItem>
                          <MenuItem value="2">飛鷹之戰</MenuItem>
                          <MenuItem value="3">迷你高爾夫</MenuItem>
                          <MenuItem value="4">Fruit射擊戰</MenuItem>
                        </Select>
                      </Field>
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="storeImage"
                        name="storeImage"
                        label="店家小圖"
                        value={values.storeImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-single-image"
                          style={{ display: 'none' }}
                          onChange={e =>
                            setSelectedImage({
                              ...selectedImage,
                              storeImg: e.target.files[0],
                              tag: 0,
                            })
                          }
                        />
                        <label htmlFor="select-single-image">
                          <Button variant="contained" color="primary" component="span">
                            商家小圖
                          </Button>
                          <p>(圖片格式: 429px * 429px, 1張)</p>
                        </label>
                        {imageUrl.storeImg && selectedImage.storeImg ? (
                          <Box mt={2} textAlign="center">
                            <div style={{ position: 'relative', width: '150px', margin: 'auto' }}>
                              <div>小圖預覽:</div>
                              <img src={imageUrl.storeImg} alt={selectedImage.storeImg.name} height="150px" />
                              <CrossButton type="button" onClick={() => removeImage(0, imgName.storeImg)}>
                                &times;
                              </CrossButton>
                            </div>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs="6">
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeAddrLng"
                        name="storeAddrLng"
                        label="地址經度"
                        fullWidth
                        multiline
                        value={values.storeAddrLng}
                        onChange={handleChange}
                        error={touched.storeAddrLng && Boolean(errors.storeAddrLng)}
                        helperText={touched.storeAddrLng && errors.storeAddrLng}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeAddrLat"
                        name="storeAddrLat"
                        label="地址緯度"
                        fullWidth
                        value={values.storeAddrLat}
                        onChange={handleChange}
                        error={touched.storeAddrLat && Boolean(errors.storeAddrLat)}
                        helperText={touched.storeAddrLat && errors.storeAddrLat}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeWebsite"
                        name="storeWebsite"
                        label="商家官網"
                        fullWidth
                        multiline
                        value={values.storeWebsite}
                        onChange={handleChange}
                        error={touched.storeWebsite && Boolean(errors.storeWebsite)}
                        helperText={touched.storeWebsite && errors.storeWebsite}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeLineoa"
                        name="storeLineoa"
                        label="LINE商家帳號"
                        fullWidth
                        value={values.storeLineoa}
                        onChange={handleChange}
                        error={touched.storeLineoa && Boolean(errors.storeLineoa)}
                        helperText={touched.storeLineoa && errors.storeLineoa}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="storeFacebook"
                        name="storeFacebook"
                        label="商家臉書"
                        fullWidth
                        value={values.storeFacebook}
                        onChange={handleChange}
                        error={touched.storeFacebook && Boolean(errors.storeFacebook)}
                        helperText={touched.storeFacebook && errors.storeFacebook}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="storeProdName"
                        name="storeProdName"
                        label="通用商品名稱"
                        fullWidth
                        value={values.storeProdName}
                        onChange={handleChange}
                        error={touched.storeProdName && Boolean(errors.storeProdName)}
                        helperText={touched.storeProdName && errors.storeProdName}
                      />
                      <Field
                        as={FormControl}
                        margin="dense"
                        id="storeBigImage"
                        name="storeBigImage"
                        label="商家大圖"
                        value={values.storeBigImage}>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-multi-image"
                          style={{ display: 'none' }}
                          onChange={e => {
                            if (e.target.files[0] !== undefined && imageUrl.storeBigImg.length < 3) {
                              setSelectedImage(prev => {
                                // console.log('大圖', e.target.files[0]);
                                let newState = Object.assign({}, prev); //shallow copy state
                                newState.storeBigImg.push(e.target.files[0]);
                                newState.tag = 1;
                                return newState;
                              });
                            }
                          }}
                        />
                        <label htmlFor="select-multi-image">
                          <Button variant="contained" color="primary" component="span">
                            商家大圖
                          </Button>
                          <p>(圖片格式: 960px * 480px, 最多3張圖)</p>
                        </label>
                      </Field>
                      {imageUrl.storeBigImg && imageUrl.storeBigImg.length > 0 ? (
                        <Grid container spacing={2}>
                          {imageUrl.storeBigImg.map((imgUrl, index) => {
                            return (
                              <Grid key={index}>
                                <Box mt={2} textAlign="center" style={{ position: 'relative' }}>
                                  <div>大圖預覽{index + 1}:</div>
                                  <img src={imgUrl} alt={`Image ${index + 1}`} height="100px" />
                                  <CrossButton type="button" onClick={() => removeImage(1, imgName.storeBigImg[index])}>
                                    &times;
                                  </CrossButton>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定修改
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
