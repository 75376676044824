import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';

// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1, width: '5%' },
    { title: '記錄時間', field: 'recordTime', type: 'time', width: '25%' },
    { title: '暱稱', field: 'userName', width: '20%' },
    { title: '分數', field: 'score', width: '10%' },
    { title: '結束狀態', field: 'endStatus', width: '40%' },
  ],
};

export default function DataTable({ effectGameDetail, getEffectGameDetail }) {
  //const dispatch = useDispatch();
  //const stores = useSelector(state => state.storeReducer);
  //const [data, setData] = useState(ad.adBasic);
  const history = useHistory();
  //useEffect(() => {}, [dispatch]);
  // 刪除Ad API
  /*const removeAd = async rowData => {
    //console.log('刪除廣告', rowData);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Ad.delete(storeId, rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/delete/${storeCode}`, token, res.status);
    });
    getAd();
  };*/

  // 編輯按鈕
  const actions = [
    /*{
      icon: () => (
        <Button variant="outlined" color="primary">
          新增廣告
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增廣告',
      onClick: (event, rowData) => {
        history.push(`/ad/edit?id=`);
      },
    },*/
    /*rowData => ({
      icon: () => (rowData.payment.statusCode === 'paid') ? <TABLE_ICONS.VisibilityIcon /> : <TABLE_ICONS.Edit />,
      tooltip: (rowData.payment.statusCode === 'paid') ? '檢視廣告' : '編輯廣告',
      onClick: () => {
        let readonly = rowData.payment.statusCode === 'paid';
        history.push(`/ad/edit?id=${rowData.id}&readonly=${readonly}`);
      },
    }),
    rowData => ({
      disabled: rowData.startDateTime === null, //沒有開始時間
      icon: () => <TABLE_ICONS.MapIcon />,
      tooltip: '執行記錄',
      onClick: (event, rowData) => {
        history.push(`/adrecord/route?id=${rowData.id}`);
      },
    }),
    rowData => ({
      disabled: rowData.startDateTime === null, //沒有開始時間
      icon: () => <TABLE_ICONS.CameraAltIcon />,
      tooltip: '即時截圖',
      onClick: (event, rowData) => {
        //openPopup(rowData);
      },
    }),*/
  ];

  return (
    <MaterialTable
      title="執行記錄內容"
      icons={TABLE_ICONS}
      columns={columns.parentsColoumns}
      data={effectGameDetail}
      options={{
        actionsColumnIndex: -1,
      }}
      /*localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="ad.table.delete.text" />,
          },
        },
      }}*/
      actions={actions}
      /*editable={{
        isDeletable: (rowData) => rowData.payment.statusCode !== 'paid',
        onRowDelete: rowData => removeAd(rowData),
      }}*/
    />
  );
}
