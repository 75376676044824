import React from 'react';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { CardMedia } from '@material-ui/core';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import FormDialog from './FormDialog';
import IntlMessages from '@jumbo/utils/IntlMessages';
//import { playlist } from '../../../@fake-db/ad-car/playlist';

export default function DataTable({ playlist, getPlaylist }) {
  //const [data, setData] = useState(playlist.playlistBasic.concat(playlist.playlistOccasion).concat(playlist.playlistGame));
  //const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  //const [rowData, setRowData] = React.useState({});

  // table 欄位標題設定
  const columns = {
    parentsColoumns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '名稱', field: 'name' },
      { title: '格式', field: 'format', editable: 'never' },
      { title: '解析度', field: 'resolution', editable: 'never' },
      {
        title: '時間(秒)',
        field: 'duration',
        editable: 'never',
        render: rowData => `${rowData.duration}`,
      },
      { title: '大小(MB)', field: 'totalFileSize', editable: 'never' },
      { title: '數量', field: 'totalFileCount', editable: 'never' },
      { title: '更新時間', field: 'updatedAt', type: 'date', editable: 'never' },
    ],
    childColumns: [
      { title: 'No.', render: rowData => rowData.tableData.id + 1 },
      { title: '名稱', field: 'name' },
      { title: '類別', field: 'type', editable: 'never' },
      { title: '格式', field: 'format', editable: 'never' },
      { title: '解析度', field: 'resolution', editable: 'never' },
      {
        title: '時間(秒)',
        field: 'duration',
        render: rowData => `${rowData.duration}`,
      },
      { title: '大小(MB)', field: 'fileSize', editable: 'never' },
    ],
  };

  // 刪除PlayList API
  const removePlayList = async rowData => {
    console.log('刪除播放清單', rowData);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    await ApiService.Playlist.delete(storeId, rowData.id)
      .then(async res => {
        await ApiService.log.addEasy(storeCode, `/playlist/detete/${storeCode}`, token, res.status);
        getPlaylist();
      })
      .catch(err => {
        console.log('error', err);
      });
  };
  // 更新PlayList API (不使用)
  const updatePlayList = async rowData => {
    console.log('更新播放清單', rowData);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    let playlistData = {
      id: rowData.id,
      name: rowData.name,
      resolution: rowData.resolution,
      format: rowData.format,
      duration: rowData.duration,
      totalFileCount: rowData.totalFileCount,
      totalFileSize: rowData.totalFileSize,
    };
    await ApiService.Playlist.update(storeId, playlistData)
      .then(async res => {
        await ApiService.log.addEasy(storeCode, `/playlist/update/${storeCode}`, token, res.status);
        getPlaylist();
      })
      .catch(err => {
        console.log('error', err);
      });
  };
  // 刪除Material API
  const removeMaterial = async (rowMaterial, rowPlaylist) => {
    console.log('刪除素材', rowMaterial);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    await ApiService.Material.delete(storeId, rowPlaylist.id, rowMaterial.id)
      .then(async res => {
        await ApiService.log.addEasy(storeCode, `/material/detete/${storeCode}`, token, res.status);
        //更新playlist
        let playlistData = {
          id: rowPlaylist.id,
          name: rowPlaylist.name,
          resolution: rowPlaylist.resolution,
          format: rowPlaylist.format,
          duration: rowPlaylist.duration - rowMaterial.duration,
          totalFileCount: rowPlaylist.totalFileCount - 1,
          totalFileSize: rowPlaylist.totalFileSize - rowMaterial.fileSize,
        };
        await ApiService.Playlist.update(storeId, playlistData).then(async res => {
          await ApiService.log.addEasy(storeCode, `/playlist/update/${storeCode}`, token, res.status);
        });
        getPlaylist();
      })
      .catch(err => {
        console.log('error', err);
      });
  };
  // 更新Material API
  const updateMaterial = async (rowMaterial, rowPlaylist) => {
    console.log('更新素材', rowMaterial);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    let materialData = {
      id: rowMaterial.id,
      name: rowMaterial.name,
      type: rowMaterial.type,
      resolution: rowMaterial.resolution,
      format: rowMaterial.format,
      duration: rowMaterial.duration,
      fileSize: rowMaterial.fileSize,
    };
    await ApiService.Material.update(storeId, rowPlaylist.id, materialData)
      .then(async res => {
        await ApiService.log.addEasy(storeCode, `/material/update/${storeCode}`, token, res.status);
        //更新playlist <- 因不確定duration有沒有變
        // let playlistData = {
        //   id: rowPlaylist.id,
        //   name: rowPlaylist.name,
        //   resolution: rowPlaylist.resolution,
        //   format: rowPlaylist.format,
        //   duration: rowPlaylist.duration - rowMaterial.duration,
        //   totalFileCount: rowPlaylist.totalFileCount,
        //   totalFileSize: rowPlaylist.totalFileSize,
        // };
        // await ApiService.Playlist.update(storeId, playlistData).then(async res => {
        //   await ApiService.log.addEasy(storeCode, `/playlist/update/${storeCode}`, token, res.status);
        // });
        getPlaylist();
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  function actionsAdd() {
    let actions = [
      {
        icon: () => <FormDialog playlist={playlist} getPlaylist={getPlaylist} insStatus={true} />,
        isFreeAction: true,
      },
    ];
    return actions;
  }

  function actionsFn(rowData) {
    let actions = [
      {
        icon: () => <FormDialog playlist={playlist} getPlaylist={getPlaylist} insStatus={false} rowData={rowData} />,
        isFreeAction: true,
      },
    ];
    return actions;
  }

  return (
    <MaterialTable
      title="播放清單"
      icons={TABLE_ICONS}
      columns={columns.parentsColoumns}
      data={playlist}
      options={{
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="material.table.delete.text" />,
          },
        },
      }}
      //actions={actions}
      actions={actionsAdd()}
      editable={{
        //onRowUpdate: rowData => updatePlayList(rowData),
        onRowDelete: rowData => removePlayList(rowData),
      }}
      detailPanel={rowData => {
        return (
          <div>
            <MaterialTable
              title={`${rowData.name} - 素材列表`}
              icons={TABLE_ICONS}
              columns={columns.childColumns}
              data={rowData.materials}
              //actions={materialActions(rowData.format)}
              //actions={actions}
              actions={actionsFn(rowData)}
              options={{
                actionsColumnIndex: -1,
                search: false,
                headerStyle: {
                  textAlign: 'center',
                  backgroundColor: 'orange', //'royalblue',
                  color: 'white',
                },
                cellStyle: {
                  textAlign: 'center',
                },
                pageSizeOptions: [3, 5, 10],
                pageSize: 3,
              }}
              style={{
                backgroundColor: 'aliceblue',
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: <IntlMessages id="material.table.delete.text" />,
                  },
                },
              }}
              editable={
                {
                  //因無法更新playlist,先停用
                  //onRowUpdate: rowMaterial => updateMaterial(rowMaterial, rowData),
                  //onRowDelete: rowMaterial => removeMaterial(rowMaterial, rowData),
                }
              }
              detailPanel={rowData => {
                return (
                  <CardMedia component={rowData.format === '圖片' ? 'img' : 'video'} image={rowData.fileName} autoPlay />
                );
              }}
            />
          </div>
        );
      }}
    />
  );
}
