import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { Box, Button, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
  fullWidth: {
    width: '100%',
  },
}));

function FormikContainer(props) {
  let { rowData, insStatus } = props;
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  //const [openMap, setOpenMap] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
  };
  //選單項目
  //初始值
  const initialValues = {
    id: '',
    muxToken: '',
    streamUrl: '',
    enabled: '1',
    startAt: new Date(),
    stopAt: new Date(),
    bannerImage: '',
    bannerWidth: '',
    bannerHeight: '',
    memo: '',
  };
  //console.log('codename:', codename);

  //設定初始資料
  if (rowData) {
    //第2次render時才有資料
    initialValues.id = rowData.id;
    initialValues.muxToken = rowData.muxToken;
    initialValues.streamUrl = rowData.streamUrl;
    initialValues.enabled = rowData.enabled;
    initialValues.startAt = rowData.startAt;
    initialValues.stopAt = rowData.stopAt;
    initialValues.bannerImage = rowData.bannerImage;
    initialValues.bannerWidth = rowData.bannerWidth;
    initialValues.bannerHeight = rowData.bannerHeight;
    initialValues.memo = rowData.memo;
  }
  // 送出前檢查欄位
  const validationSchema = Yup.object({
    //code: Yup.string().required('**必填'),
  });

  // 新增 or 更新
  const onSubmit = async formData => {
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let streamingData = {
      id: formData.id,
      muxToken: formData.muxToken,
      streamUrl: formData.streamUrl,
      enabled: formData.enabled,
      startAt: formData.startAt,
      stopAt: formData.stopAt,
      bannerImage: formData.bannerImage,
      bannerWidth: formData.bannerWidth,
      bannerHeight: formData.bannerHeight,
      memo: formData.memo,
    };

    if (insStatus) {
      //insStatus=true 新增
      await ApiService.VendingStreaming.add(streamingData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/vending/streaming/add`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      await ApiService.VendingStreaming.update(streamingData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/vending/streaming/update`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    }
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          console.log('formik', formik);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormikControl control="hidden" name="id" fullWidth errors={formik.errors} touched={formik.touched} />
                  <Box className={classes.section}>
                    <InputLabel id="lbMuxToken">直播Token*</InputLabel>
                    <ErrorMessage name="muxToken" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl control="input" name="muxToken" fullWidth errors={formik.errors} touched={formik.touched} />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbStreamUrl">直播URL*</InputLabel>
                    <ErrorMessage name="streamUrl" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl control="input" name="streamUrl" fullWidth errors={formik.errors} touched={formik.touched} />
                  </Box>
                  <Box className={classes.section}>
                    <input
                      id="enabled"
                      name="enabled"
                      type="radio"
                      placeholder=""
                      onChange={formik.handleChange}
                      value="1"
                      checked={formik.values.enabled == true}
                    />
                    <label class="MuiFormLabel-root">啟用　</label>
                    <input
                      id="disabled"
                      name="enabled"
                      type="radio"
                      placeholder=""
                      onChange={formik.handleChange}
                      value="0"
                      checked={formik.values.enabled == false}
                    />
                    <label class="MuiFormLabel-root">不啟用</label>
                  </Box>
                  {/*<Box className={classes.section}>
                    <InputLabel id="lbStreaming">路線KML</InputLabel>
                    <ErrorMessage name="streaming" component="div" className={classes.sectionValidErrMsg} />
                    <Field
                      className={classes.fullWidth}
                      component="textarea"
                      name="streaming"
                      rows="5"
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                    <MapDialog streamingId={formik.values.id} disabled={insStatus ? true : false} />
                  </Box>*/}
                </Grid>

                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit">
                      {insStatus ? '確定新增' : '確定更新'}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
